import React, { Component } from 'react'
import { HTMLTag } from '../Utilities/HTMLTag';
import Utility, { getVW } from "../Utilities/Utility";
import TemplateSettingsManager from './TemplateSettingsManager';
import { getLocalisedText } from '../Translate/LanguageManager';
export default class DraftWatermarkManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        let defaultStyle = {
            position: 'absolute',
            userSelect: 'none',
            zIndex: 100000,
        }

        if(this.getIsShowDraftWatermark()) {
            return (
                <>
                    <text id={HTMLTag.DRAFT_WATERMARK_MANAGER}
                        style={{
                            ...this.getDraftWatermarkSettings(defaultStyle),
                        }}>
                        {this.getDraftLabel()}
                    </text >
                    {this.props.mainContent}
                </>

            )
        }
        return this.props.mainContent 
    }

    getIsShowDraftWatermark() {
        let draftWatermarkSettings = TemplateSettingsManager.getDraftWatermarkSettings()
        let opacity = draftWatermarkSettings?.opacity ?? 20
        if(opacity > 0 && this.props.data && this.props.data.isDraft) {
            return true
        }
        return false
    }

    getDraftWatermarkSettings(defaultStyle) {
        let draftWatermarkSettings = TemplateSettingsManager.getDraftWatermarkSettings()

        let fontSize = draftWatermarkSettings?.fontSize ?? 100
        const fontSizeVW = getVW(fontSize)
        const opacity = draftWatermarkSettings?.opacity ?? 20
        const rotation = draftWatermarkSettings?.rotation ?? 0
        const transform = `rotate(${rotation}deg)`

        return {
            ...defaultStyle,
            opacity: opacity / 100,
            top: draftWatermarkSettings?.top ?? 0,
            left: draftWatermarkSettings?.left ?? 0,
            transform: transform,
            ...Utility.getFontStyleProp({ fontSize: fontSizeVW }, this.props.data.printScale),
        }
    }

    getDraftLabel() {
        return TemplateSettingsManager.getDraftWatermarkSettings()?.label ?? getLocalisedText('draft')
    }
}
