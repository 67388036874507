import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
// import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
// import { getLocalisedText } from "../../Translate/LanguageManager";
// import Utility, { getIsBill, getIsInvoice, getIsOrder, getIsQuotation, getIsSalesOrder } from "../../Utilities/Utility";
import PopupHeaderComponent from "./PopupHeaderComponent";
// import WatermarkUtility, { watermarkPosition, watermarkType } from "../../Utilities/WatermarkUtility";
// import ComponentManager from "../../Manager/ComponentManager";

import {
    TemplateSettingsManager,
    getLocalisedText,
    Utility,  
    getIsBill,
    getIsInvoice, 
    getIsOrder, 
    getIsQuotation, 
    getIsSalesOrder,
    getIsRequestForQuotation,
    Asset,
    ComponentManager,
    WatermarkUtility
} from 'deskera-doc-builder-lib';
import { watermarkPosition, watermarkType } from "deskera-doc-builder-lib/src/Helper/types";
import { getIsPurchaseInwardQuotation } from "deskera-doc-builder-lib";

export default class WatermarkPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.getWatermarkSelectionList(),
            selectedPosition: this.getSelectedPosition(),
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 600,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'watermark'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.renderMainContainerSection()}
        </>;
    }

    getDefaultWatermarkConfiguration () {
        var type = this.props.documentType
        if (getIsQuotation(type) ||
            getIsSalesOrder(type) ||
            getIsInvoice(type) ||
            getIsRequestForQuotation(type) ||
            getIsPurchaseInwardQuotation(type) ||
            getIsBill(type) ||
            getIsOrder(type)) {
            return WatermarkUtility.getDefaultWatermarkList()
        }

        return []
    }

    getWatermarkSelectionList() {
        var existingWatermarkConfiguration = WatermarkUtility.getWatermarkConfiguration()
        var watermarkSelectionList = this.getDefaultWatermarkConfiguration()
        if (existingWatermarkConfiguration !== undefined) {
            if (existingWatermarkConfiguration.elements !== undefined && existingWatermarkConfiguration.element !== null) {
                if (existingWatermarkConfiguration.elements.length > 0) {
                    watermarkSelectionList = this.getWatermarkListCorrection(existingWatermarkConfiguration.elements)
                }
            }
        }

        return watermarkSelectionList
    }

    getWatermarkListCorrection(list) {
        var duplicateService = list.find(x => x.type === watermarkType.duplicate_service)

        if (duplicateService !== undefined) {
            var newList = list.filter(x => x.type !== watermarkType.duplicate_service)

            newList.forEach((element, index) => {
                if (element.type === watermarkType.duplicate_goods) {
                    element.label = 'duplicate'
                }
                else if (element.type === watermarkType.custom) {
                    if(element.value === '') {
                        element.value = 'extra_copy'
                    }
                }
            });

            //add quadruplicate
            var quadruplicate = newList.find(x => x.type === watermarkType.quadruplicate)
            if(quadruplicate === undefined) {
                var defaultQuadruplicate = {
                    index: 3,
                    type: watermarkType.quadruplicate,
                    label: 'quadruplicate',
                    value: 'extra_copy',
                    isSelected: false,
                }
                newList.splice(3, 0, defaultQuadruplicate)
            }

            newList.forEach((element, index) => {
                element.index = index
            });

            list = newList
        }

        return list
    }

    getSelectedPosition() {
        var position = WatermarkUtility.getDefaultPositionList()[0].type
        var existingWatermarkConfiguration = WatermarkUtility.getWatermarkConfiguration()
        if (existingWatermarkConfiguration !== undefined) {
            if (existingWatermarkConfiguration.position !== undefined && existingWatermarkConfiguration.position !== null) {
                if (existingWatermarkConfiguration.position !== '') {
                    position = existingWatermarkConfiguration.position
                }
            }
        }

        return position
    }

    renderMainContainerSection() {
        return <>
            <div style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 5,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderWatermarkListSection()}
                    {this.renderPositionPicker()}
                </div>
            </div>
        </>
    }

    renderWatermarkListSection() {
        if(this.state.data === undefined || this.state.data === null) {
            return <></>
        }

        if(this.state.data.length === 0) {
            return <></>
        }
        return this.state.data.map((element, index) => {
            return this.renderItemsContainer(element, index)
        });
    }

    renderItemsContainer(element, index) {
        return <>
            <div
                id={'row_id_' + index}
                className={"ColumnDiv"}
                style={{ width: '100%', paddingLeft: 13, paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                    <div className="RowDiv" style={{ paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                        <div className="ColumnDiv" style={{ width: '100%' }}>
                            <div className="RowDiv">
                                {this.renderCustomTextContainer(element, index)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Line" style={{marginLeft: 0, height: 2, width: '100%' }} />
            </div>
        </>
    }

    renderCustomTextContainer(element, index) {
        return <>
            <div className="parent-width">
                <div className="parent-width">
                    <div className="RowDiv">
                        {/* <div className="ColumnDiv"
                            style={{
                                width: '5%'
                            }}
                        >
                            {this.renderWatermarkSelectionCheckBox(element, index)}
                        </div> */}
                        <div className="ColumnDiv"
                            style={{
                                width: '40%'
                            }}
                        >
                            {getLocalisedText(element.label)}:
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: '49%'
                            }}>
                            {this.renderTextInput(element, (event, element) => this.onTextChanged(event, element, index))}
                        </div>
                        {this.renderResetValue(index)}
                    </div>
                </div>
            </div>
        </>
    }

    renderWatermarkSelectionCheckBox(element, index) {
        return (
            <input type="checkbox"
                checked={this.getIsWatermarkSelectionChecked(index)}
                style={{ cursor: "pointer" }}
                id={'row_checkbox_id_' + index}
                onClick={() => {
                    this.onWatermarkSelectionCheck(element, index)
                }} />
        )
    }

    getIsWatermarkSelectionChecked(index) {
        var data = this.state.data
        if(data !== undefined && data !== null) {
            if(data.length > 0) {
                if(data.length > index) {
                    var item = data[index]
                    if(item !== undefined && item !== null) {
                        if (item.isSelected !== undefined) {
                            return item.isSelected
                        }
                    }
                }
            }
        }

        return false
    }

    renderTextInput(element, onChange) {
        return (
            <input
                className="EditableLabelTableColumn"
                type="text"
                dir={Utility.getInputDirection()}
                name={element.label}
                style={{ fontSize: 14, padding: 4, width: '96%', fontWeight: 500, boxSizing: 'border-box' }}
                value={this.getDefaultText(element.value)}
                onChange={e => onChange(e, element)}
                onBlur={() => {
                    this.setState({ isTextFieldFocus: false })
                }}
            />
        )
    }

    renderResetValue(index) {
        return <div className='ColumnDiv'
            style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                justifyContent: 'center',
            }}
            onClick={(event) => this.resetValue(event, index)}
        >
            <img
                src={Asset.icon.ic_refresh_black}
                alt=""
                style={{
                    width: 16,
                    height: 16,
                    alignSelf: 'center',
                }}
            />
        </div>
    }

    resetValue(event, selectedIndex) {
        event.stopPropagation()
        var newData = []
        if (this.state.data === undefined || this.state.data === null) {
            return
        }
        if (this.state.data.length === 0) {
            return
        }

        this.state.data.forEach((element, index) => {
            if (selectedIndex === index) {
                element.value = WatermarkUtility.getDefaultWatermarkValueList(element.type)
            }
            newData.push(element)
        });

        this.setState({
            data: newData
        })
    }

    getDefaultText(field) {
        if (field !== undefined || field !== null) {
            return getLocalisedText(field)
        }
        return ''
    }

    onTextChanged(event, item, itemIndex) {
        var newData = this.state.data
        var value = event.target.value

        newData.forEach((element, index) => {
            if (item.type === element.type && itemIndex === index ) {
                if (element.value !== undefined && element.value !== null) {
                    element.value = value
                }
            }
        });
        this.setState({
            data: newData
        })
    }

    renderPositionPicker() {
        return <div className="ColumnDiv pt-s pb-s"
            style={{
                paddingLeft: 13,
                paddingRight: 13,
                boxSizing: 'border-box',
            }}
        >
            <div className="RowDiv">
                {getLocalisedText('position')}
            </div>
            <div className="RowDiv">
                {this.renderPortionList(watermarkPosition.top)}
            </div>
            {ComponentManager.addVerticalSpace(60)}
            <div className="RowDiv">
                {this.renderPortionList(watermarkPosition.bottom)}
            </div>
        </div>
    }

    renderPortionList(portion) {
        var portionList = WatermarkUtility.getDefaultPositionList().filter(x => x.portion === portion)
        if (portionList.length > 0) {
            return portionList.map((element) => {
                return this.renderPositionText(element)
            });
        }
        return undefined
    }

    renderPositionText(element) {
        var position = 'center'
        if(element.label !== 'middle') {
            position = element.label
        }

        var className = 'RowDiv'
        if(element.label === 'right') {
            className = 'RowReverseDiv'
        }
        if (this.state.selectedPosition !== undefined && this.state.selectedPosition !== null) {
            if(element.type === this.state.selectedPosition) {
                return <div className={className} style={{ justifyContent: position }}>
                    {ComponentManager.getButton(element.label, 'rgba(55, 115, 225, 1.0)', 'white', () => this.onWatermarkPositionClick(element), undefined, false, false)}
                </div>
            }
        }
        return <div className={className} style={{ justifyContent: position }}>
            {ComponentManager.getButton(element.label, 'white', 'rgba(55, 115, 225, 1.0)', () => this.onWatermarkPositionClick(element), undefined, false, false)}
        </div>
    }

    onWatermarkPositionClick(element) {
        this.setState({
            selectedPosition: element.type
        })
    }

    onWatermarkSelectionCheck(element, selectedIndex) {
        var data = this.state.data
        var prevSelected = data.find(x => x.isSelected)
        if(prevSelected !== undefined) {
            if (prevSelected.type !== element.type) {
                data.forEach((item, index) => {
                    if(item.type === prevSelected.type) {
                        item.isSelected = false
                    }
                    if (index === selectedIndex) {
                        item.isSelected = true
                    }
                });
            }
        }
        else {
            data[selectedIndex].isSelected = true
        }

        this.setState({
            data: data
        })
    }

    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => {
        var watermarkConfiguration = TemplateSettingsManager.defaultTemplateSettings.documentInfo.watermark
        if (watermarkConfiguration === undefined && watermarkConfiguration === null)  {
            watermarkConfiguration = TemplateSettingsManager.getDefaultWatermark()
        }

        watermarkConfiguration.elements = this.state.data
        watermarkConfiguration.position = this.state.selectedPosition
        // var selectedWatermark = this.state.data.find(x => x.isSelected)
        // if(selectedWatermark !== undefined) {
        //     AppManager.setWatermarkText(getLocalisedText(selectedWatermark.value))
        // }
        TemplateSettingsManager.updateWatermark(watermarkConfiguration)
        this.props.onClosePressed()
    }
}
