export const APP_NAME = {
    BOOKS: "BOOKS",
    DOC_BUILDER: "DOC_BUILDER"
}

export const ExportType = {
    PRINT: "Print",
    EMAIL: "Email",
    DOWNLOAD_PDF: "Download Pdf",
    VIEW_PDF: "View Pdf",
    PREVIEW_PDF: "Preview Pdf"
}

export const COLOR_BLUE = "#3773e1";
export const COLOR_LIGHTGRAY = "#f4f4f4";
export const COLOR_LIGHTGRAY2 = "rgb(230,230,230)";
export const COLOR_LIGHTGRAY3 = "rgb(80, 80, 80)";

export const COLOR_GRAY = "rgb(174,174,174)";

export const LINE_ITEM_HEIGHT = "2.8vw";
export const LINE_ITEM_HEIGHT_2 = "1.64706vw";
export const PIXEL_TO_VW = 14.3;

export const PAGE_WIDTH = "55.45vw";
export const PAGE_HEIGHT = "78.63vw";
export const PAGE_WIDTH_LANDSCAPE = "55.45vw"; // /1.418 = 55.45
export const PAGE_HEIGHT_LANDSCAPE = "39.11vw"; // 1.418 = 39.11
// export const PAGE_WIDTH = "80vw";
// export const PAGE_HEIGHT = "113.44vw";
export const PAGE_PADDING = "2.8vw";
export const NEGATIVE_PAGE_PADDING = "-2.8vw";
export const PAGE_PADDING_DOUBLE = "5.6vw";
export const PAGE_BORDER_PADDING = "2.0vw";
export const PAGE_BORDER_PADDING_HALF = "1.0vw";
export const PAGE_BORDER_PADDING_DOUBLE = "4.0vw";
export const PAGE_PRINT_RATIO = 1.7024;
export const US_CHEQUE_HEIGHT = 310;
export const US_CHEQUE_DETAIL_HEIGHT = 290;

export const PRINT_SCALE = 1.7
export const PREVIEW_SCALE = 0.85

export const EDITOR_WIDTH = 270

export const IMG_MAX_UPLOAD_SIZE = 500 * 1024;

export const LANDING_PAGE_URL = "https://invoice-template.deskera.com/"
export const DESKERA_URL = "https://www.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool"
export const DESKERA_GO_URL = "https://gous.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool"

export const USERDEFAULT_savedData = "savedDocdata"
export const MOBILE_WIDTH = 800

// templateId === 36 || templateId === 38 || templateId === 40 || templateId === 42 || templateId === 43 || templateId === 44
export const INDIA_TEMPLATES_ID = [36, 38, 40, 42, 43, 44, 45, 46, 47, 48, 51, 61, 62, 63]

export const REMOVE_REPEATABLE_TABLE_HEADER = [51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63]

export const REDUCE_SPACING_TEMPLATE_ID = [46, 47, 48, 49, 50, 51]

export const TABLE_GRID_TEMPLATE_ID = [43, 48]

export const REPEATABLE_HEADER_FOOTER_TEMPLATE_ID = [51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63];

export const FONT_SIZE = {
    xSmall: "0.7vw",
    small: "0.76vw",
    regular: "0.91vw",
    medium: "1.12vw",
    xMedium: "1.39vw",
    large: "1.67vw",
    xLarge: "2.09vw",
    xxLarge: "2.45vw",
    xxxLarge: "3.5vw"
}

export const FONT_SIZE_PRINT = {
    xSmall: "1.19vw",
    small: "1.3vw",
    regular: "1.55vw",
    medium: "1.91vw",
    xMedium: "2.36vw",
    large: "2.84vw",
    xLarge: "3.56vw",
    xxLarge: "4.17vw",
    xxxLarge: "5.96vw"
}

export const FONT_FAMILY = [
    { label: 'Default', value: '' },
    { label: 'Arial', value: 'Arial' },
    { label: 'Arial Black', value: 'Arial Black' },
    { label: 'Arial narrow', value: 'Arial narrow' },
    { label: 'Arial Nova', value: 'Arial Nova' },
    { label: 'Calibri', value: 'Calibri' },
    // { label: 'Calibri Light', value: 'Calibri Light' },
    // { label: 'Comic Sans', value: 'Comic Sans' },
    { label: 'Comic Sans MS', value: 'Comic Sans MS' },
    // { label: 'Cursive', value: 'cursive' },
    // { label: 'Fantasy', value: 'fantasy' },
    { label: 'Helvetica', value: 'helvetica' },
    { label: 'Impact', value: 'Impact' },
    { label: 'Japanese', value: 'Japanese' },
    // { label: 'Javanese Text', value: 'Javanese Text' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Lao', value: 'Lao' },
    { label: 'Monospace', value: 'monospace' },
    { label: 'Montserrat', value: 'montserrat' },
    { label: 'Sans-serif', value: 'sans-serif' },
    { label: 'Segoe UI', value: 'Segoe UI' },
    { label: 'Simplified Arabic', value: 'Simplified Arabic' },
    { label: 'Simplified Chinese', value: 'Simplified Chinese' },
    { label: 'Serif', value: 'serif' },
    { label: 'System UI', value: 'system-ui' },
    { label: 'Times', value: 'times' },
]

export const ERROR_MESSAGES = {
    EMAIL_API_ERROR: 'Error occurred while sending email, please try again after sometime.',
    EMAIL_API_SUCCESS: 'Email has been sent successfully, please check your inbox.',
}

// A5      2480 x 1748
// A4      3508 x 2480
// A3      4961 x 3605
// Legel   4205 x 2551
// letter  3295 x 2551

export const PAPER_ORIENTATION = {
    landscape: '[LANDSCAPE]',
    portrait: '[PORTRAIT]',
}

export const PAPER_FORMAT = {
    a3: {
        height: 4961,
        width: 3605,
        ratio: 1.37614424411,
        ratioLandscape: 0.72666801,
        heightInMM: 420,
        widthInMM: 297,
    },
    a4: {
        height: 3508,
        width: 2480,
        ratio: 1.41451612903,
        ratioLandscape: 0.7069553,
        heightInMM: 297,
        widthInMM: 210,
    },
    a5: {
        height: 2480,
        width: 1748,
        ratio: 1.41876430206,
        ratioLandscape: 0.72666801,
        heightInMM: 210,
        widthInMM: 148,
    },
    legal: {
        height: 3900,
        width: 2250,
        ratio: 1.73333333333,
        ratioLandscape: 0.576923077,
        heightInMM: 356,
        widthInMM: 216,
    },
    letter: {
        height: 3000,
        width: 2250,
        ratio: 1.33333333333,
        ratioLandscape: 0.75,
        heightInMM: 279,
        widthInMM: 216,
    },
    three_five: {
        height: 1983.33333333,
        width: 680,
        ratio: 2.916666666666667,
        ratioLandscape: 0.342857143,
        heightInMM: 0,
        widthInMM: 0,
    },
    four_six: {
        height: 1458.33333333,
        width: 500,
        ratio: 2.916666666666667,
        ratioLandscape: 0.342857143,
        heightInMM: 0,
        widthInMM: 0,
    }
}

export const INDIA_STATE_MAPPING = [
    {
        code: "Andaman & Nicobar Islands",
        name: "Andaman & Nicobar Islands",
        stateCode: "35"
    },
    { code: "Andhra Pradesh", name: "Andhra Pradesh", stateCode: "37" },
    { code: "Arunachal Pradesh", name: "Arunachal Pradesh", stateCode: "12" },
    { code: "Assam", name: "Assam", stateCode: "18" },
    { code: "Bihar", name: "Bihar", stateCode: "10" },
    { code: "Chandigarh", name: "Chandigarh", stateCode: "04" },
    { code: "Chhattisgarh", name: "Chhattisgarh", stateCode: "22" },
    {
        code: "Dadra & Nagar Haveli",
        name: "Dadra & Nagar Haveli",
        stateCode: "26"
    },
    { code: "Daman & Diu", name: "Daman & Diu", stateCode: "25" },
    { code: "Delhi", name: "Delhi", stateCode: "07" },
    { code: "Goa", name: "Goa", stateCode: "30" },
    { code: "Gujarat", name: "Gujarat", stateCode: "24" },
    { code: "Haryana", name: "Haryana", stateCode: "06" },
    { code: "Himachal Pradesh", name: "Himachal Pradesh", stateCode: "02" },
    { code: "Jammu & Kashmir", name: "Jammu & Kashmir", stateCode: "01" },
    { code: "Jharkhand", name: "Jharkhand", stateCode: "20" },
    { code: "Karnataka", name: "Karnataka", stateCode: "29" },
    { code: "Kerala", name: "Kerala", stateCode: "32" },
    { code: "Lakshadweep", name: "Lakshadweep", stateCode: "31" },
    { code: "Madhya Pradesh", name: "Madhya Pradesh", stateCode: "23" },
    { code: "Maharashtra", name: "Maharashtra", stateCode: "27" },
    { code: "Manipur", name: "Manipur", stateCode: "14" },
    { code: "Meghalaya", name: "Meghalaya", stateCode: "17" },
    { code: "Mizoram", name: "Mizoram", stateCode: "15" },
    { code: "Nagaland", name: "Nagaland", stateCode: "13" },
    { code: "Odisha", name: "Odisha", stateCode: "21" },
    { code: "Puducherry", name: "Puducherry", stateCode: "34" },
    { code: "Punjab", name: "Punjab", stateCode: "03" },
    { code: "Rajasthan", name: "Rajasthan", stateCode: "08" },
    { code: "Sikkim", name: "Sikkim", stateCode: "11" },
    { code: "Tamil Nadu", name: "Tamil Nadu", stateCode: "33" },
    { code: "Telangana", name: "Telangana", stateCode: "36" },
    { code: "Tripura", name: "Tripura", stateCode: "16" },
    { code: "Uttar Pradesh", name: "Uttar Pradesh", stateCode: "09" },
    { code: "Uttarakhand", name: "Uttarakhand", stateCode: "05" },
    { code: "West Bengal", name: "West Bengal", stateCode: "19" },
    { code: "Ladakh", name: "Ladakh", stateCode: "38" }
];

export const TEXT_PLACEHOLDER = [
    { label: 'none', value: '' },
    { label: 'company_name', value: 'companyName' },
    { label: 'contact_name', value: 'contactName' },
    { label: 'document_date', value: 'documentDate' },
    { label: 'document_type', value: 'documentType' },
    { label: 'current_date', value: 'currentDate' },
    { label: 'total', value: 'total' },
    { label: 'amount_in_words', value: 'amountInWords' },
];

export const CONSTANTS = {
    FIXED_ALLOWANCE : "Fixed Allowance",
    FIXED_ALLOWANCE_TYPE : "fixedAllowance",
    EMPTY_SPACE: "",
    PRODUCT_GROUP: "product_group"
};

export const CURRENCY = {
    ZWD: '$',
    ZMW: 'ZK',
    ZMK: 'ZK',
    ZAR: 'R',
    YER: '﷼',
    XPF: '₣',
    XOF: 'CFA',
    XCD: '$',
    XAF: 'FCFA',
    WST: 'WS$',
    VUV: 'VT',
    VND: '₫',
    VEF: 'Bs',
    UZS: 'лв',
    UYU: '$U',
    USD: '$',
    UGX: 'USh',
    UAH: '₴',
    TZS: 'TSh',
    TWD: 'NT$',
    TTD: 'TT$',
    TRY: '₺',
    TOP: 'T$',
    TND: 'د.ت',
    TMT: 'T',
    TMM: 'T',
    TJS: 'ЅM',
    THB: '฿',
    SZL: 'E',
    SYP: '£',
    STD: 'Db',
    SSP: '£',
    SRD: '$',
    SOS: 'S',
    SLL: 'Le',
    SKK: 'Sk',
    SHP: '£',
    SGD: '$',
    SEK: 'kr',
    SDG: 'SD',
    SCR: '₨',
    SBD: 'Si$',
    SAR: '﷼',
    RWF: 'FRw',
    RUB: '₽',
    RSD: 'Дин.',
    RON: 'lei',
    QAR: '﷼',
    PYG: '₲',
    PLN: 'zł',
    PKR: '₨',
    PHP: '₱',
    PGK: 'K',
    PEN: 'S/',
    PAB: 'B/.',
    OMR: '﷼',
    NZD: '$',
    NPR: '₨',
    NOK: 'kr',
    NIO: 'C$',
    NGN: '₦',
    NAD: '$',
    MZN: 'MT',
    MYR: 'RM',
    MXN: '$',
    MWK: 'MK',
    MVR: 'Rf',
    MUR: '₨',
    MTL: '₤',
    MRO: 'UM',
    MOP: 'MOP$',
    MNT: '₮',
    MMK: 'K',
    MKD: 'ден',
    MGA: 'Ar',
    MDL: 'L',
    MAD: 'DH',
    LYD: 'ل.د',
    LVL: 'Ls',
    LTL: 'Lt',
    LSL: 'L',
    LRD: '$',
    LKR: '₨',
    LBP: '£',
    LAK: '₭',
    KZT: 'лв',
    KYD: '$',
    KWD: 'د.ك',
    KRW: '₩',
    KPW: '₩',
    KMF: 'CF',
    KHR: '៛',
    KGS: 'лв',
    KES: 'KSh,',
    JPY: '¥',
    JOD: 'د.ا',
    JMD: 'J$',
    ISK: 'kr',
    IRR: '﷼',
    IQD: 'ع.د',
    INR: '₹',
    ILS: '₪',
    IDR: 'Rp',
    HUF: 'Ft',
    HTG: 'G',
    HRK: 'kn',
    HNL: 'L',
    HKD: '$',
    GYD: 'GY$',
    GTQ: 'Q',
    GNF: 'FG',
    GMD: 'D',
    GIP: '£',
    GHS: '₵',
    GHC: 'GH₵',
    GEL: 'ლ',
    GBP: '£',
    FKP: '£',
    FJD: 'FJ$',
    EUR: '€',
    ETB: 'ብር',
    ERN: 'ናቕፋ',
    EGP: '£',
    EEK: 'EEK',
    DZD: 'دج',
    DOP: 'RD$',
    DKK: 'kr.',
    DJF: 'Fdj',
    CZK: 'Kč',
    CYP: '£',
    CVE: '$',
    CUC: '$',
    CRC: '₡',
    COP: '$',
    CNY: '¥',
    CLP: '$',
    CHF: 'CHF',
    CDF: 'FC',
    CAD: '$',
    BZD: 'BZ$',
    BYR: 'Br',
    BWP: 'P',
    BTN: 'Nu.',
    BSD: '$',
    BRL: 'R$',
    BOB: 'b$',
    BND: '$',
    BMD: '$',
    BIF: 'FBu',
    BHD: '.د.ب',
    BGN: 'лв',
    BDT: '৳',
    BBD: '$',
    BAM: 'KM',
    AZN: '₼',
    AWG: 'Afl',
    AUD: '$',
    ARS: '$',
    AOA: 'Kz',
    ANG: 'Naf',
    AMD: 'դր',
    ALL: 'Lek',
    AFN: '؋',
    AED: 'د.إ'
};


export const BARCODE_FORMAT = {
    CODE_128: 'CODE128',
    CODE_39: 'CODE39',
    EAN_8: 'EAN8',
    EAN_13: 'EAN13',
    UPC_A: 'UPC'
};

export const BARCODE_FONT_SIZE = 8;

export const DateFormat = {
    DD_MM_YYYY_SLASH: 'dd/mm/yyyy',
    MM_DD_YYYY_SLASH: 'mm/dd/yyyy',
    MMDDYYYY: 'mmddyyyy',
    DDMMYYYY: 'ddmmyyyy',
    DDMMMMYYYY: 'ddmmmmyyyy',
    DD_MM_YYYY_DASH: 'dd-mm-yyyy',
    MM_DD_YYYY_DASH: 'mm-dd-yyyy',
    DDMMMYYYY: 'ddmmmyyyy',
    MMMDDYYYY: 'mmmddyyyy',
    YYYY_MM_DD_DASH: 'yyyy-mm-dd',
    DD_MM_YYYY_HH_MM_SS_SLASH: 'dd/MM/yyyy hh:mm:ss a',
    D_MMM_YYYY_SPACE: 'd mmm yyyy'
}

export const EditorInfoType = {
    documentType: 'documentType',
    companyLogo: 'companyLogo',
    companyName: 'companyName',
    from: 'from',
    clientBillToAddress: 'clientBillToAddress',
    clientShipToAddress: 'clientShipToAddress',
    clientShipFromAddress: 'clientShipFromAddress',
    currency: 'currency',
    refNumber: 'refNumber',
    termsAndCondition: 'termsAndCondition',
    notes: 'notes',
    startDate: 'startDate',
    dueDate: 'dueDate',
    additionalDate: 'additionalDate',

    billToTitle: 'billToTitle',
    shipToTitle: 'shipToTitle',
    shipFromTitle: 'shipFromTitle',

    signature: 'signature',
    logo: 'logo',

    eInvoiceSection: 'eInvoiceSection',
    linkDocumentNumber: 'linkDocumentNumber',
    repeatableHeader: 'repeat ableHeader',
    repeatableHeaderFooter: 'repeatableHeaderFooter',
    repeatableFooter: 'repeatableFooter',
    headerFooter: "headerFooter",
    payslipField: "payslipField",
    payslipTable: "payslipTable",
    payslipOvertime: "payslipOvertime",
    payslipLOP: "payslipLOP",
    payslipBasic: "payslipBasic",
    termsAndConditionTitle: 'termsAndConditionTitle',
    notesTitle: 'notesTitle',
    additionalLeftFooterTable: 'additionalLeftFooterTable',
    watermark: 'watermark',
    placeOfSupply: 'placeOfSupply',
    sourceOfSupply: 'sourceOfSupply',
    destinationOfSupply: 'destinationOfSupply',
    paymentFooter: "paymentFooter",
    generatedMessage: "generatedMessage",
    documentDetails: "documentDetails",
    topCustomFieldTable: 'topCustomFieldTable',
    bottomCustomFieldTable: 'bottomCustomFieldTable',
    childCustomFieldTable: 'childCustomFieldTable',
    topSectionAboveTable: 'topSectionAboveTable',
    tableColumnAlignment: 'tableColumnAlignment',
    tableColumnVerticalAlignment: 'tableColumnVerticalAlignment',
    payslipEarningsFields: "payslipEarningsFields",
    accountProductTable: 'accountProductTable',
    exchangeRate: 'exchangeRate',
    exchangeRateTitle: 'exchangeRateTitle',
    checkTable: 'checkTable',
    checkTableCurrency: 'checkTableCurrency',
    isIncludesBomProductTable: 'isIncludesBomProductTable',
    documentTitle: 'documentTitle',
    hsnSacTaxTable: 'hsnSacTaxTable',
    approvalSection: 'approvalSection',
    customApprovalSection: 'customApprovalSection',
    pageCountSettings: 'pageCountSettings',
    paperMargin: 'paperMargin',
    draftWatermark: 'draftWatermark',
}

export const CurrencyFormatType = {
    SYMBOL: "symbol",
    NAME: "name",
    SYMBOL_NAME: "symbol_name",
    CUSTOM: "custom"
}

export const TableDimensionType = {
    ROW_HEIGHT: "rowHeight",
    PADDING_HORIZONTAL: "paddingHorizontal",
    PADDING_VERTICAL: "paddingVertical",
}

export const AdditionalTableType = {
    amountInWords: 'amountInWords',
    qrCode: 'qrCode',
    customText: 'customText',
    qrCodeWithText: 'qrCodeWithText',
    image: 'image',
    imageWithText: 'imageWithText',
    customField: 'customField',
    total: 'total',
    outstandingBalance: 'outstandingBalance',
    outstandingBalanceIncludesCurrent: 'outstandingBalanceIncludeCurrent',
    subTotalInWords: 'subTotalInWords',
    cgstInWords: 'cgstInWords',
    igstInWords: 'igstInWords',
    sgstInWords: 'sgstInWords',
    roundOffInWords: 'roundOffInWords',
    outstandingBalanceInWords: 'outstandingBalanceInWords',
    outstandingBalanceIncludesCurrentInWords: 'outstandingBalanceIncludesCurrentInWords',
    totalTaxAmountInWords: 'totalTaxAmountInWords',
    beforeTaxInWords: 'beforeTaxInWords',
    globalDiscountInWords: 'globalDiscountInWords',
    additionalChargeInWords: 'additionalChargeInWords',
}

export const DocumentSectionType = {
    header: 'header',
    footer: 'footer',
    data: 'data',
}

export const TableColumnPopupType = {
    lineNumber: 'lineNumber',
    name: "name",
    status: "status",
    code: 'code',
    remark: "remark",
    productCode: "productCode",
    productName: 'productName',
    description: 'description',
    quantity: 'quantity',
    unitPrice: 'unitPrice',
    tax: 'tax',
    discount: 'discount',
    amount: 'amount',
    paymentAmount: 'paymentAmount',
    taxAmount: 'taxAmount',
    accountName: 'accountName',
    totalTaxAmount: 'totalTaxAmount',
    snNumber: 'snNumber',
    productTax: 'productTax',
    productTaxAmount: 'productTaxAmount',
    productDiscount: 'productDiscount',
    uom: 'uom',
    amountBeforeTax: 'amountBeforeTax',
    requiredQty: 'requiredQty',
    requiredQuantity: 'requiredQuantity',
    committedQuantity: 'committedQuantity',
    receivedQuantity: 'receivedQuantity',
    serialBatch: 'serialBatch',
    orderNo: 'orderNo',
    warehouseCode: 'warehouseCode',
    warehouseName: 'warehouseName',
    paymentFooter: 'paymentFooter',
    beforeTax: 'beforeTax',
    outstandingAmount: 'outstandingAmount',
    amountInWords: 'amountInWords',
    productNameWithDescription: 'productNameWithDescription',
    invoiceNumber: 'invoiceNumber',
    billNumber: 'billNumber',
    totalInvoiceAmount: 'totalInvoiceAmount',
    totalBillAmount: 'totalBillAmount',
    paymentMade: 'paymentMade',
    currentDueAmount: 'currentDueAmount',
    currentBalance: 'currentBalance',
    paymentMedium: 'paymentMedium',
    date : 'date',
    journalEntryNumber: 'journalEntryNumber',
    accountCode: 'accountCode',
    credit: 'credit',
    debit: 'debit',
    barcode: 'barcode',
    unit: 'unit',
    packedQuantity: 'packedQuantity',
    invoiceOrQuotation: 'invoiceOrQuotation',
    quantityToPick: 'quantityToPick',
    quantityRequiredForOrder: 'quantityRequiredForOrder',
    id: 'id',
    picked: 'picked',
    delivery: "delivery",
    carrier: "carrier",
    numberOfCarton: "numberOfCarton",
    weight: "weight",
    trackingNumber: "trackingNumber",
    image: "image",
    productCustomField: "productCustomField",
    quantityWithUom: "quantityWithUom",
    serialNumber: "serialNumber",
    batchNumber: "batchNumber",
    manufacturingDate: "manufacturingDate",
    expiryDate: "expiryDate",
    globalDiscount: "globalDiscount",
    additionalCharge: "additionalCharge",
    accountCustomField: "accountCustomField",
    totalAmount: 'totalAmount',
    subTotalAmount: 'subtotal',
    outstandingBalance: 'outstandingBalance',
    outstandingBalanceIncludesCurrent: 'outstandingBalanceIncludesCurrent',
    hsn: 'hsn',
    roundingOff: 'roundingOff',
    orderPrepCompleted: 'orderPrepCompleted',
    sourceWarehouseName: 'sourceWarehouseName',
    sourceWarehouseCode: 'sourceWarehouseCode',
    destinationWarehouseName: 'destinationWarehouseName',
    destinationWarehouseCode: 'destinationWarehouseCode',
    productGlobalDiscount: 'productGlobalDiscount',
    productUnitPriceAfterDiscount: 'productUnitPriceAfterDiscount',
    productDiscountPerUnit: 'productDiscountPerUnit',
    cogs: 'cogs',
    expectedDeliveryDate: 'expectedDeliveryDate',
    contactName: 'contactName',
    contactCode: 'contactCode',
    parameterName: 'parameterName',
    parameterValue: 'parameterValue',
    qcIncoming: 'qcIncoming',
    qcFinal: 'qcFinal',
    fulfilledQty: 'fulfilledQty',
    returnedQty: 'returnedQty',
    rate: 'rate',
    per: 'per',
    kindOfPacking: 'kindOfPacking',
    dueOn: 'dueOn',
    cost: 'cost',
    targetWarehouseName: 'targetWarehouseName',
    targetWarehouseCode: 'targetWarehouseCode',
    selectedRow: 'selectedRow',
    selectedRack: 'selectedRack',
    selectedBin: 'selectedBin',
    requestedQty: 'requestedQty',
    issuedQty: 'issuedQty',
    refDocument: 'refDocument',
    refDocumentDate: 'refDocumentDate',
    qtyConvertedToWo: 'qtyConvertedToWo',
    availableQuantity: 'availableQuantity',
    POBillNoDate: 'POBillNoDate',
    shortExcessRejectedQty: 'shortExcessRejectedQty',
    acceptedQty: 'acceptedQty',
    basicAmt: 'basicAmt',
    charges: 'charges',
    documentDate: 'documentDate',
    documentType: 'documentType',
    documentNumber: 'documentNumber',
    currency: 'currency',
    memo: 'memo',
    particular: 'particular',
    amountInBase: 'amountInBase',
    balanceInBase: 'balanceInBase',
    serialBatchCustomFields: 'serialBatchCustomFields',
}

export const SignatureColumnType = {
    name: 'name',
    signature: 'signature',
    preparedBy: 'preparedBy',
    approvedBy: 'approvedBy',
}

export const TableTotalColumnList = [
    TableColumnPopupType.quantity,
    TableColumnPopupType.productDiscount,
    TableColumnPopupType.productTax,
    TableColumnPopupType.amountBeforeTax,
    TableColumnPopupType.amount
]

export const TableTotalColumnListForJournalEntry = [
    TableColumnPopupType.credit,
    TableColumnPopupType.debit,
]

export const TableTotalColumnListForSOA = [
    TableColumnPopupType.credit,
    TableColumnPopupType.debit,
    TableColumnPopupType.amountInBase,
]

export const CUSTOM_FIELD_SHOW_OPTION = {
    NONE: 'NONE',
    SHOW_LABEL: 'SHOW_LABEL',
    SHOW_VALUE: 'SHOW_VALUE',
    SHOW_BOTH: 'SHOW_BOTH'
}

export const WeekdayList = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const LongMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const ShortMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const ShortMonth_3_char = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const USStateData = [
    { code: 'AL', name: 'Alabama' },
    { code: 'AK', name: 'Alaska' },
    { code: 'AS', name: 'American Samoa' },
    { code: 'AZ', name: 'Arizona' },
    { code: 'AR', name: 'Arkansas' },
    { code: 'CA', name: 'California' },
    { code: 'CO', name: 'Colorado' },
    { code: 'CT', name: 'Connecticut' },
    { code: 'DE', name: 'Delaware' },
    { code: 'DC', name: 'District of Columbia' },
    { code: 'FL', name: 'Florida' },
    { code: 'GA', name: 'Georgia' },
    { code: 'GU', name: 'Guam' },
    { code: 'HI', name: 'Hawaii' },
    { code: 'ID', name: 'Idaho' },
    { code: 'IL', name: 'Illinois' },
    { code: 'IN', name: 'Indiana' },
    { code: 'IA', name: 'Iowa' },
    { code: 'KS', name: 'Kansas' },
    { code: 'KY', name: 'Kentucky' },
    { code: 'LA', name: 'Louisiana' },
    { code: 'ME', name: 'Maine' },
    { code: 'MD', name: 'Maryland' },
    { code: 'MA', name: 'Massachusetts' },
    { code: 'MI', name: 'Michigan' },
    { code: 'MN', name: 'Minnesota' },
    { code: 'MS', name: 'Mississippi' },
    { code: 'MO', name: 'Missouri' },
    { code: 'MT', name: 'Montana' },
    { code: 'NE', name: 'Nebraska' },
    { code: 'NV', name: 'Nevada' },
    { code: 'NH', name: 'New Hampshire' },
    { code: 'NJ', name: 'New Jersey' },
    { code: 'NM', name: 'New Mexico' },
    { code: 'NY', name: 'New York' },
    { code: 'NC', name: 'North Carolina' },
    { code: 'ND', name: 'North Dakota' },
    { code: 'MP', name: 'Northern Mariana Islands' },
    { code: 'OH', name: 'Ohio' },
    { code: 'OK', name: 'Oklahoma' },
    { code: 'OR', name: 'Oregon' },
    { code: 'PA', name: 'Pennsylvania' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'RI', name: 'Rhode Island' },
    { code: 'SC', name: 'South Carolina' },
    { code: 'SD', name: 'South Dakota' },
    { code: 'TN', name: 'Tennessee' },
    { code: 'TX', name: 'Texas' },
    { code: 'UM', name: 'United States Minor Outlying Islands' },
    { code: 'UT', name: 'Utah' },
    { code: 'VT', name: 'Vermont' },
    { code: 'VI', name: 'Virgin Islands, U.S.' },
    { code: 'VA', name: 'Virginia' },
    { code: 'WA', name: 'Washington' },
    { code: 'WV', name: 'West Virginia' },
    { code: 'WI', name: 'Wisconsin' },
    { code: 'WY', name: 'Wyoming' }
];
export const SECURITY_GATE_DEFAULT_TEMPLATE_ID = 57;
