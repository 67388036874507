import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
import PopupHeaderComponent from "./PopupHeaderComponent";
import CustomFieldTablePopupRow from "./CustomFieldTablePopupRow";

import {
    getLocalisedText,
    getIsInvoice, 
    getIsQuotation,
    getIsSalesOrder, 
    getIsOrder,
    getIsBill,
    getIsRequestForQuotation,
    getIsStockTransfer,
    getIsStockIssue,
    getIsStockRequest,
    getIsGoodsReceipt,
    ApprovalSectionUtility,
} from 'deskera-doc-builder-lib'
import { TemplateSettingsManager } from "deskera-doc-builder-lib";
import { getIsPurchaseInwardQuotation } from "deskera-doc-builder-lib";
export default class ApprovalSectionPopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            data: this.getList(),
            lineSpace: TemplateSettingsManager.getApprovalSectionSettings()?.lineSpace ?? 3 ,
            position: TemplateSettingsManager.getApprovalSectionSettings()?.position ?? 'bottom',
            textAlign: TemplateSettingsManager.getApprovalSectionSettings()?.textAlign ?? 'center',
            tableBorder: TemplateSettingsManager.getApprovalSectionSettings()?.tableBorder ?? 'hide',
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 350,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'approval_section'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.renderMainContainerSection()}
        </>;
    }

    getList () {
        var type = this.props.documentData.type
        if (getIsQuotation(type) ||
            getIsInvoice(type) ||
            getIsRequestForQuotation(type) ||
            getIsPurchaseInwardQuotation(type) ||
            getIsBill(type) ||
            getIsOrder(type) ||
            getIsSalesOrder(type)) {
            return ApprovalSectionUtility.getApprovalList()
        }
        if (getIsStockTransfer(type)) {
            return ApprovalSectionUtility.getStockTransferDefaultList()
        }
        if (getIsStockIssue(type)) {
            return ApprovalSectionUtility.getStockIssuesDefaultList()
        }
        if (getIsStockRequest(type)) {
            return ApprovalSectionUtility.getStockRequestDefaultList()
        }
        if (getIsGoodsReceipt(type)) {
            return ApprovalSectionUtility.getGoodsReceiptNoteDefaultList()
        }

        return []
    }

    renderMainContainerSection() {
        return <>
            <div style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 5,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderTableBorderSection()}
                    {this.renderPositionSection()}
                    {this.renderTextAlignmentSection()}
                    {this.renderLineSpaceSection()}
                    {this.renderApprovalListSection()}
                </div>
            </div>
        </>
    }

    onUpdateLabel(item, name) {
        let data = this.state.data

        data.forEach(element => {
            if (element.type === item.type) {
                element.label = name
            }
        });

        this.setState({
            data
        })
    }

    onResetTitle(item) {
        let data = this.state.data
        data.forEach(element => {
            if (element.type === item.type) {
                element.label = item.originalLabel
            }
        });

        this.setState({
            data
        })
    }

    renderApprovalListSection() {
        if(this.state.data === undefined || this.state.data === null) {
            return <></>
        }

        if(this.state.data.length === 0) {
            return <></>
        }

        return this.state.data.map((item, index) => {
            return <CustomFieldTablePopupRow
                item={item}
                customFields={this.state.data}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                onUpdateLabel={(name) => this.onUpdateLabel(item, name)}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                options={undefined}
                onUpdateOptions={undefined}
                isTextEditable={true}
                isRemovable={false}
                onDeleteItem={undefined}
                isResetTitle={true}
                onResetTitle={(item) => this.onResetTitle(item)}
            />
        });
    }

    renderLineSpaceSection() {
        var lineSpaces = []
        for (let index = 0; index < 6; index++) {
            lineSpaces.push(index)
        }

        let spaces = lineSpaces.map(element => {
            const style = element === this.state.lineSpace ? {
                color: 'white',
                backgroundColor: 'rgb(55, 115, 225)'
            } : undefined
            return (
                <div
                    className="HeaderButton clicked-effect"
                    style={{ 
                        ...style,
                        marginLeft: 10, 
                        userSelect: 'none'
                    }}
                    onClick={() => this.onLineSpaceChanged(element)}
                >
                    {element}
                </div>
            )
        });

        return <div className="RowDiv" style={{
            alignItems: 'baseline'
        }}>
            <div style={{
                color: "black",
                fontWeight: "600",
                paddingLeft: 18,
                paddingTop: 10,
                paddingRight: 72,
            }}>
                {getLocalisedText('spacing')}
            </div>
            {spaces}
        </div>
    }

    onLineSpaceChanged(element) {
        this.setState({
            lineSpace: element ?? 0
        })
    }

    renderPositionSection() {
        var positionList = ['top', 'bottom']

        let positions = positionList.map(element => {
            const style = element === this.state.position ? {
                color: 'white',
                backgroundColor: 'rgb(55, 115, 225)'
            } : undefined
            return (
                <div
                    className="HeaderButton clicked-effect"
                    style={{
                        ...style,
                        marginLeft: 10,
                        userSelect: 'none',
                    }}
                    onClick={() => this.onPositionChanged(element)}
                >
                    {getLocalisedText(element)}
                </div>
            )
        });

        return <div className="RowDiv" style={{
            alignItems: 'baseline'
        }}>
            <div style={{
                color: "black",
                fontWeight: "600",
                paddingLeft: 18,
                paddingTop: 10,
                paddingRight: 146,
            }}>
                {getLocalisedText('position')}
            </div>
            {positions}
        </div>
    }

    onPositionChanged(element) {
        this.setState({
            position: element ?? 'bottom'
        })
    }

    renderTextAlignmentSection() {
        var positionList = ['left', 'center', 'right']

        let textAligns = positionList.map(element => {
            const style = element === this.state.textAlign ? {
                color: 'white',
                backgroundColor: 'rgb(55, 115, 225)'
            } : undefined
            return (
                <div
                    className="HeaderButton clicked-effect"
                    style={{
                        ...style,
                        marginLeft: 10,
                        userSelect: 'none',
                    }}
                    onClick={() => this.onTextAlignmentChanged(element)}
                >
                    {getLocalisedText(element)}
                </div>
            )
        });

        return <div className="RowDiv" style={{
            alignItems: 'baseline'
        }}>
            <div style={{
                color: "black",
                fontWeight: "600",
                paddingLeft: 18,
                paddingTop: 10,
                paddingRight: 49,
            }}>
                {getLocalisedText('text_alignment')}
            </div>
            {textAligns}
        </div>
    }

    onTextAlignmentChanged(element) {
        this.setState({
            textAlign: element ?? 'center'
        })
    }


    renderTableBorderSection() {
        var borderList = ['show', 'hide']

        let borders = borderList.map(element => {
            const style = element === this.state.tableBorder ? {
                color: 'white',
                backgroundColor: 'rgb(55, 115, 225)'
            } : undefined
            return (
                <div
                    className="HeaderButton clicked-effect"
                    style={{
                        ...style,
                        marginLeft: 10,
                        userSelect: 'none',
                    }}
                    onClick={() => this.onTableBorderChanged(element)}
                >
                    {getLocalisedText(element)}
                </div>
            )
        });

        return <div className="RowDiv" style={{
            alignItems: 'baseline'
        }}>
            <div style={{
                color: "black",
                fontWeight: "600",
                paddingLeft: 18,
                paddingTop: 10,
                paddingRight: 160,
            }}>
                {getLocalisedText('border')}
            </div>
            {borders}
        </div>
    }

    onTableBorderChanged(element) {
        this.setState({
            tableBorder: element ?? 'hide'
        })
    }

    onSelectClick(item) {
        var documentLabels = this.state.data
        documentLabels.forEach((element) => {
            if (element.type === item.type) {
                element.isSelected = !element.isSelected
            }
        });
        this.setState({
            data: documentLabels,
        })
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if (startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev) => {
        if (ev.currentTarget.id !== undefined) {
            this.onRowIndexChange(ev.currentTarget.id)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if (dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if (this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if (startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'none'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'none'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'none'
        }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            textCol.innerHTML = this.startIndexText
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'flex'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'flex'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'flex'
        }
    }

    onRowIndexChange(endIndex) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if (this.startIndex === endIndex) {
            this.showSelectedRow(startIndex)
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var documentLabels = this.state.data
        documentLabels.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = documentLabels.filter(x => x.index === startIndex)
        if (selectedItem.length > 0) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            data: []
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
            this.setState({
                data: sortedList,
            })
        })
    }

    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => {
        let approvalSettings = TemplateSettingsManager.getApprovalSectionSettings()

        approvalSettings.tableBorder = this.state.tableBorder
        approvalSettings.position = this.state.position
        approvalSettings.textAlign = this.state.textAlign
        approvalSettings.lineSpace = this.state.lineSpace
        approvalSettings.elements = this.state.data?.length !== 0 ? this.state.data : approvalSettings.elements
        TemplateSettingsManager.updateApprovalSectionSettings(approvalSettings)

        this.props.onClosePressed()
    }
}
