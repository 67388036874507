
import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
import PopupHeaderComponent from "./PopupHeaderComponent";
import {
    TemplateSettingsManager,
    getLocalisedText,
    Utility,
} from 'deskera-doc-builder-lib';

export default class DraftWatermarkPopup extends Component {


    constructor(props) {
        super(props);
        this.state = {
            data: this.getPositionList(),
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 'auto',
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'draft_watermark'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.renderMainContainerSection()}
        </>;
    }

    getDefaultPositionConfiguration() {

        return []
    }

    getPositionList() {
        let draftWatermark = TemplateSettingsManager.getDraftWatermarkSettings()

        let positionList = [
            { index: 1, type: 'label', label: getLocalisedText('label'), value: draftWatermark?.label ?? getLocalisedText('draft'), measurement: '' },
            { index: 2, type: 'top', label: getLocalisedText('top'), value: draftWatermark?.top ?? 0, measurement: 'px' },
            { index: 3, type: 'left', label: getLocalisedText('left'), value: draftWatermark?.left ?? 0, measurement: 'px' },
            { index: 4, type: 'fontSize', label: getLocalisedText('text_size'), value: draftWatermark?.fontSize ?? 100, measurement: 'px' },
            { index: 5, type: 'opacity', label: getLocalisedText('opacity'), value: draftWatermark?.opacity ?? 20, measurement: '%' }, 
            { index: 6, type: 'rotation', label: getLocalisedText('rotation'), value: draftWatermark?.rotation ?? 0, measurement: '°' }, 
        ]

        return positionList
    }

    getMeasurement() {
        return 'px'
    }

    renderMainContainerSection() {
        return <>
            <div style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 5,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderPositionListSection()}
                </div>
            </div>
        </>
    }

    renderPositionListSection() {
        if (this.state.data === undefined || this.state.data === null) {
            return <></>
        }

        if (this.state.data.length === 0) {
            return <></>
        }
        return this.state.data.map((element, index) => {
            return this.renderItemsContainer(element, index)
        });
    }

    renderItemsContainer(element, index) {
        return <>
            <div
                id={'row_id_' + index}
                className={"ColumnDiv"}
                style={{ width: '100%', paddingLeft: 13, paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                    <div className="RowDiv" style={{ paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                        <div className="ColumnDiv" style={{ width: '100%' }}>
                            <div className="RowDiv">
                                {this.renderCustomTextContainer(element, index)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Line" style={{ marginLeft: 0, height: 2, width: '100%' }} />
            </div>
        </>
    }

    renderCustomTextContainer(element, index) {
        return <>
            <div className="parent-width"
                style={{
                    color: 'black'
                }}>
                <div className="parent-width">
                    <div className="RowDiv">
                        <div className="ColumnDiv"
                            style={{
                                width: '50%'
                            }}
                        >
                            {getLocalisedText(element.label)}:
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: element.measurement !== '' ? '39%' : '49%'
                            }}>
                            {this.renderTextInput(element, (event, element) => this.onTextChanged(event, element, index))}
                        </div>
                        {
                            element.measurement !== '' &&
                            <div className="ColumnDiv"
                                style={{
                                    width: '10%'
                                }}
                            >
                                {element.measurement}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    }

    renderTextInput(element, onChange) {
        return (
            <input
                className="EditableLabelTableColumn"
                type="text"
                dir={Utility.getInputDirection()}
                name={element.label}
                style={{ fontSize: 14, padding: 4, width: '96%', fontWeight: 500, boxSizing: 'border-box', textAlign: 'right', color: 'black' }}
                value={this.getDefaultText(element.value)}
                onChange={e => onChange(e, element)}
                onBlur={() => {
                    this.setState({ isTextFieldFocus: false })
                }}
            />
        )
    }

    getDefaultText(field) {
        if (field !== undefined || field !== null) {
            return getLocalisedText(field)
        }
        return ''
    }

    onTextChanged(event, item, itemIndex) {
        let newData = this.state.data
        let value = event.target.value
        let isValidData = false
        
        const type = newData[itemIndex]?.type ?? undefined
        let result = 0 

        if(type !== 'label') {
            result = parseFloat(value)
            isValidData = !isNaN(result)
        }
        else {
            isValidData = true
            result = event.target.value
        }
        
        if (type === 'opacity') {
            if (result < 0 || result > 100) {
                isValidData = false
            }
        }
        if (type === 'rotation') {
            if (result < 0 || result > 180) {
                isValidData = false
            }
        }
        if (isValidData) {
            newData.forEach((element, index) => {
                if (itemIndex === index) {
                    if (element.value !== undefined && element.value !== null) {
                        element.value = result
                    }
                }
            });
            this.setState({
                data: newData
            })
        }
    }


    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => {
        let draftWatermark = TemplateSettingsManager.getDraftWatermarkSettings()

        this.state.data.forEach(element => {
            if(element.type !== 'label') {
                const result = parseFloat(element.value)
                if (!isNaN(result)) {
                    draftWatermark[element.type] = result
                }
            }
            else {
                draftWatermark[element.type] = element.value
            }
        });

        TemplateSettingsManager.updateDraftWatermarkSettings(draftWatermark)
        this.props.onClosePressed()
    }
}
