import React, { Component } from 'react';
import './App.css';
import './Components/TemplateEditors/css/Editor.css';
import HeaderMenu from './Components/HeaderMenu';
import DocumentEditor from './Components/TemplateEditors/DocumentEditor';
import TemplatesListScreen from './Components/Popup/TemplatesListScreen';
import EmailComposer from './Components/EmailComposer';
import SignInPopup from "./Components/Popup/SignInPopup";
import TableColumnPopup from "./Components/Popup/TableColumnPopup";
import PaperFormatPopup from './Components/Popup/PaperFormatPopup';
import SaveTemplatePopup from './Components/Popup/SaveTemplatePopup';
import AfterSaveTemplatePopup from './Components/Popup/AfterSaveTemplatePopup';
import CustomFieldTablePopup from './Components/Popup/CustomFieldTablePopup';
import DeleteTemplatePopup from './Components/Popup/DeleteTemplatePopup';
import EmptyCustomFieldPopup from './Components/Popup/EmptyCustomFieldPopup';
import HeaderFooterPopup from './Components/Popup/HeaderFooterPopup';
import AddressFormatPopup from './Components/Popup/AddressFormatPopup';
import TagManager from 'react-gtm-module';
import Cookies from "universal-cookie";
import PayslipFieldConfigurationPopup from './Components/Popup/PayslipFieldConfigurationPopup';
import PayslipTableConfigurationPopup from './Components/Popup/PayslipTableConfigurationPopup';
import AdditionalTablePopup from './Components/Popup/AdditionalTablePopup';
import WatermarkPopup from './Components/Popup/WatermarkPopup';
import DocumentDetailsPopup from './Components/Popup/DocumentDetailsPopup';
import TopSectionAboveTablePopup from './Components/Popup/TopSectionAboveTablePopup';
import TableColumnAlignmentPopup from './Components/Popup/TableColumnAlignmentPopup';
import TableColumnVerticalAlignmentPopup from './Components/Popup/TableColumnVerticalAlignmentPopup';
import PayslipEarningsFieldConfigurationPopup from './Components/Popup/PayslipEarningsFieldConfigurationPopup';
import DocumentLabelPopup from './Components/Popup/DocumentLabelPopup';
import ChequeDetailsPopup from './Components/Popup/ChequeDetailsPopup';

import {
    DocumentManager,
    AppManager,
    ApiManager,
    ComponentManager,
    TemplateManager,
    DocumentDesignerManager,
    TemplateSettingsManager,
    TemplateRepository,
    ShareLinkRepository,
    PrintInfoRepository,
    CustomFieldManager,
    MultiPageManager,
    HeaderFooterManager,
    IndiaTaxParser,
    NewDataParser,
    WatermarkManager,
    DocumentLabelManager,
    ApiConstants,
    ComponentType,
    ScreenState,
    HTMLTag,
    PdfUtility,
    Utility,
    getFontSize,
    getIsBill,
    getIsInvoice,
    getIsRequestForQuotation,
    getIsOrder,
    getIsPayslip,
    getIsCheque,
    getIsQuotation,
	getIsInspectionReport,
    getPageHeight,
    getPageWidth,
    getVW,
    getIsPayrollCheck,
    getIsSalesOrder,
    getIsEwayBillDetails,
    getIsEwayBillSummary,
    getIsWorkOrder,
    getIsJobCard,
    getIsQCDocument,
    getLocalisedText,
    PayslipUtility,
    PayrollCheckUtility,
    MultiPageUtility,
    EDITOR_WIDTH,
    FONT_SIZE,
    NEGATIVE_PAGE_PADDING,
    PAGE_PADDING,
    EditorInfoType,
    showAlert,
    showToast,
    TOAST_TYPE,
    SettingPanel,
    TableColumnPopupType
} from "deskera-doc-builder-lib";
import ChechkTablePopup from './Components/Popup/CheckTablePopup';
import { getIsSalesReturn } from 'deskera-doc-builder-lib';
import { getIsBOMModule } from 'deskera-doc-builder-lib';
import BOMComponentTablePopup from './Components/Popup/BOMComponentTablePopup';
import { ExportType } from './common';
import { AlertType } from 'deskera-doc-builder-lib/src/Helper/types';
import { getIsMachine } from 'deskera-doc-builder-lib';
import ApprovalSectionPopup from './Components/Popup/ApprovalSectionPopup';
import PageCountPopup from './Components/Popup/PageCountPopup';
import CustomApprovalSectionPopup from './Components/Popup/CustomApprovalSectionPopup';
import { getIsQCInspection } from 'deskera-doc-builder-lib';
import QCInspectionTablePopup from './Components/Popup/QCInspectionTablePopup';
import NewMultiPageUtility from 'deskera-doc-builder-lib/src/Utilities/NewMultiPageUtility';
import { getIsPurchaseInwardQuotation } from 'deskera-doc-builder-lib';
import PaperMarginPopup from './Components/Popup/PaperMarginPopup';
import DraftWatermarkManager from 'deskera-doc-builder-lib/src/Manager/DraftWatermarkManager';
import DraftWatermarkPopup from './Components/Popup/DraftWatermarkPopup';

export default class App extends Component {

    ///////////////////////////////////////////////////////////////
    ///////////////////////  Initial Setup  ///////////////////////
    ///////////////////////////////////////////////////////////////
    templateRepo = new TemplateRepository()

    fromERPData = null
    settingsData = null
    documentCode = null
    documentType = null
    isLoaded = false
    view = ''


    ///////////////////////////////////////////////////////////////
    ///////////////////////   Constructor   ///////////////////////
    ///////////////////////////////////////////////////////////////
    constructor(props) {
        //initialize initial/default Template Settings JSON
        TemplateSettingsManager.initializeDefaultTemplate()

        super(props);

        this.state = {
            documentData: null,
            documentDataList: null,
            templateId: null,
            templateIdFromParam: null,
            templateType: null,
            canShowPage: false,
            isTappedOutside: false,
            isReadOnlyMode: false,
            isFromERP: false,
            sharableURL: "",
            needTemplateSelectionPopup: true,
            needDeskeraSuitesPopup: true,
            needSignInPopup: false,
            showMailComposer: false,
            pdfURL: '',
            userLoggedIn: false,
            generatingFile: false,
            isSavingTemplate: false,
            isDownloadingPDF: false,
            isViewPdf: false,
            isPrintingPDF: false,
            isEmailPDF: false,
            isPreviewingPdf: false,
            showEditorView: false,
            showThemeSelectionPopUp: false,
            isAddComponentPopupRequired: false,
            isViewComponentPopupRequired: false,
            componentList: TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList,
            selectedComponent: undefined,
            globalSetting: {
                zoomRatio: 1,
                showGrid: false
            },
            showGrid: false,
            isDragging: false,
            id: TemplateSettingsManager.getComponentMaxId(),
            isImagePopupRequired: false,
            doubleClickComponent: undefined,
            isTableColumnPopupRequired: false,
            isQCInspectionTableColumnPopupRequired: false,
            tableColumnType: undefined,
            isAfterSaveTemplatePopupRequired: false,
            isSaveTemplatePopupRequired: false,
            isDeleteTemplateRequired: false,
            isEmptyCustomFieldPopupRequired: false,
            deleteTemplateItem: undefined,
            tableColumnData: TemplateSettingsManager.defaultTemplateSettings.tableInfo,
            currentLanguage: TemplateSettingsManager.defaultTemplateSettings.languageInfo,
            isGeneratingShareableLink: false,
            bypassUserLoginCheck: false,
            isPaperFormatPopupRequired: false,
            exportType: '',
            isCustomFieldPopupRequired: false,
            isPreviewMode: false,
            printInfo: undefined,
            isSharingMode: false,
            isHeaderFooterPopupRequired: false,
            isAddressFormatRequired: false,
            addressType: undefined,
            isPayslipFieldPopupRequired: false,
            isPayslipTablePopupRequired: false,
            documentUpdateCount:0,
            isAdditionalTablePopupRequired: false,
            isWatermarkRequiredPopupRequired: false,
            isDocumentPopupRequired: false,
            customFieldPosition: undefined,
            childCustomField: undefined,
            isTopSectionAboveTablePopupRequired: false,
            isTableColumnAlignmentPopupRequired: false,
            isTableColumnVerticalAlignmentPopupRequired: false,
            isBOMComponentTablePopupRequired: false,
            isPayslipEarningsPopupRequired: false,
            isDocumentLabelPopupRequired: false,
            isCheckTableFormatRequired: false,
            isPageCountPopupRequired: false,
            isApprovalSectionPopupRequired: false,
            isCustomApprovalSectionPopupRequired: false,
            isPaperMarginRequired: false,
            isDraftWatermarkPopupRequired: false,
            isChequePopupRequired: false
        };
        this.handleLoad = this.handleLoad.bind(this);
    }
    ///////////////////////////////////////////////////////////////
    ///////////////   Life Cycle Related Methods   ///////////////
    ///////////////////////////////////////////////////////////////
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        window.onbeforeunload = function (e) {
            if (TemplateSettingsManager.hasTemplateChanged() && this.state?.printInfo === undefined) {
                e.returnValue = "onbeforeunload";
                return "onbeforeunload";
            }
        };
        window.addEventListener('load', this.handleLoad);

        // let isFromLocalStore = false
        // let savedValue = Utility.getPersistentValue(USERDEFAULT_savedData)
        // if (savedValue !== null && savedValue !== "null") {
        //     isFromLocalStore = true
        //     Utility.setPersistentValue("null", USERDEFAULT_savedData)
        // }

        // this.getParamVariables(isFromLocalStore, savedValue);
        // ApiManager.checkIfUserLoggedIn(this)
        if(!this.getIsContainPrintParamFromUrl()) {
            TagManager.initialize({ gtmId: 'GTM-KQ32K3P' });
            ApiManager.checkIfUserLoggedIn(this)
        }
        else {
            this.setState({
                userLoggedIn: false,
            }, () =>{
                this.getParamVariables()
            })
        }
        // ApiManager.getCustomFields(this)

        this.copyPasteEventHandler()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('load', this.handleLoad)
    }

    updateDimensions = () => {
        if(this.state.isSharingMode) {
            if (Utility.getIsMobileView()) {
                var printInfo = {id: '1'}
                this.setState({ printInfo: printInfo})
                TemplateSettingsManager.setPrintInfo(printInfo)
            }
            else {
                this.setState({printInfo: undefined})
                TemplateSettingsManager.setPrintInfo(undefined)
            }
        }
        else {
            this.setState({})
        }
    };

    copyPasteEventHandler = () => {
        window.addEventListener("paste", function (e) {
            e.preventDefault();
            var text = (e.originalEvent || e).clipboardData.getData('text/plain');
            document.execCommand("insertHTML", false, text);
        });
    }

    handleLoad = () => {
        this.isLoaded = true
        this.setState({})
    }
    ///////////////////////////////////////////////////////////////
    /////////////////   Render Related Methods   ///////////////
    ///////////////////////////////////////////////////////////////
    render() {
        return (
            <div className="App" id={HTMLTag.MAIN_HOLDER_ID}
                style={{
                    height: this.state.printInfo === undefined ? '100vh' : undefined,
                }}>
                {ComponentManager.getPaperMargin()}
                {(this.state.isViewPdf || this.state.isEmailPDF) && this.getLoadingMsg(this.state.isViewPdf ? "preparing_document_print" : "preparing_document_email")}
                {!Utility.getIsShareMobileView(this.state.isSharingMode) && !this.state.isPreviewMode && this.state.canShowPage && this.renderHeaderMenu()}
                {this.state.printInfo !== undefined ? this.getDocumentTemplate() : this.viewForWeb()}
                {this.state.isTableColumnPopupRequired && this.renderTableColumnPopup()}
                {this.state.isAfterSaveTemplatePopupRequired &&
                    <AfterSaveTemplatePopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentType={this.state.documentData.type}
                    />
                }
                {this.state.isAdditionalTablePopupRequired &&
                    <AdditionalTablePopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentType={this.state.documentData.type}
                        documentData={this.state.documentData}
                    />
                }
                {this.state.isWatermarkRequiredPopupRequired &&
                    <WatermarkPopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentType={this.state.documentData.type}
                    />
                }
                {this.state.isDocumentLabelPopupRequired &&
                    <DocumentLabelPopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentData={this.state.documentData}
                    />
                }
                {this.state.isPageCountPopupRequired &&
                    <PageCountPopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentData={this.state.documentData}
                    />
                }
                {this.state.isApprovalSectionPopupRequired &&
                    <ApprovalSectionPopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentData={this.state.documentData}
                    />
                }
                {this.state.isCustomApprovalSectionPopupRequired &&
                    <CustomApprovalSectionPopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentData={this.state.documentData}
                    />
                }
                {this.state.isQCInspectionTableColumnPopupRequired &&
                    <QCInspectionTablePopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        onEditTableColumnButtonClicked={() => {
                            this.setState({
                                isTableColumnPopupRequired: true,
                                isQCInspectionTableColumnPopupRequired: false,
                            })
                        }}
                        tableColumnData={JSON.parse(JSON.stringify(this.state.tableColumnData))}
                        documentData={this.state.documentData}
                    />
                }
                {this.state.isPaperMarginRequired &&
                    <PaperMarginPopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentData={this.state.documentData}
                    />
                }
                {this.state.isDraftWatermarkPopupRequired &&
                    <DraftWatermarkPopup
                        onClosePressed={this.onSaveTemplatePopupClose}
                        documentData={this.state.documentData}
                    />
                }
                {this.state.isEmptyCustomFieldPopupRequired && <EmptyCustomFieldPopup onClosePressed={this.onEmptyCustomFieldClose} />}
                {this.state.isSaveTemplatePopupRequired && <SaveTemplatePopup documentData={this.state.documentData} onClosePressed={this.onSaveTemplatePopupClose} onSaveTemplate={(templateName) => {
                    this.setState({ isSavingTemplate: true, isSaveTemplatePopupRequired: false }, () => {
                        this.saveTemplateSettings(null, templateName)
                    })
                }} />}
                {this.state.isAddressFormatRequired && this.renderAddressFormatPopup()}
                {this.state.isCheckTableFormatRequired && this.renderCheckTableFormatPopup()}
                {this.state.isDeleteTemplateRequired && this.renderDeleteTemplatePopup()}
                {this.state.showMailComposer && this.renderEmailPopup()}
                {this.state.isPaperFormatPopupRequired && this.renderPaperFormatPopup()}
                {this.state.isCustomFieldPopupRequired && this.renderCustomFieldPopup()}
                {this.state.needSignInPopup && this.renderSignInPopup()}
                {this.state.isHeaderFooterPopupRequired && <HeaderFooterPopup
                    documentData={this.state.documentData}
                    onClosePressed={this.onSaveTemplatePopupClose}
                />}
                {this.state.isPayslipFieldPopupRequired && this.renderPayslipFieldConfigurationPopup()}
                {this.state.isPayslipTablePopupRequired && this.renderPayslipTableConfigurationPopup()}
                {this.state.isDocumentPopupRequired && this.renderDocumentDetailsPopup()}
                {this.state.isTopSectionAboveTablePopupRequired && this.renderTopSectionAboveTablePopup()}
                {this.state.isTableColumnAlignmentPopupRequired && this.renderTableColumnAlignmentPopup()}
                {this.state.isBOMComponentTablePopupRequired && this.renderBOMComponentTablePopup()}
                {this.state.isTableColumnVerticalAlignmentPopupRequired && this.renderTableColumnVerticalAlignmentPopup()}
                {this.state.isPayslipEarningsPopupRequired && this.renderPayslipEarningsFieldConfigurationPopup()}
                {this.state.isChequePopupRequired && this.renderChequeDetailsPopup()}
            </div>
        );
    }

    renderTableColumnPopup() {
        return (
            <div >
                <div className="greyBackground" />
                <TableColumnPopup
                    data={JSON.parse(JSON.stringify(this.state.tableColumnData))}
                    onTableColumnPopupClose={this.onTableColumnPopupClose}
                    onTableColumnPopupSave={(data, tableType) => this.onTableColumnPopupSave(data, tableType)}
                    documentType={this.state.documentData.type}
                    documentData={this.state.documentData}
                    tableType={this.state.tableColumnType}
                />
            </div>
        );
    }

    renderDeleteTemplatePopup() {
        return <DeleteTemplatePopup
            onCancelClicked={() => this.resetDeleteTemplateItem()}
            onDeleteTemplate={()=> this.onDeleteTemplate()}
            template={this.state.deleteTemplateItem}
            />
    }

    renderPaperFormatPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <PaperFormatPopup
                    onCancelClicked={() => {
                        this.setState({
                            isPaperFormatPopupRequired : false
                        })
                    }}
                    exportType={this.state.exportType}
                    onPaperFormatSave={(type) => {
                        this.setState({ exportType: '', isPaperFormatPopupRequired: false }, () => { this.onPrintOrExportPdf(type)})
                    }}
                />
            </div>
        );
    }

    renderCustomFieldPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <CustomFieldTablePopup
                    onCancelClicked={() => {
                        this.setState({
                            isCustomFieldPopupRequired: false,
                            customFieldPosition: undefined,
                            childCustomField: undefined,
                        })
                    }}
                    onSaveClicked={() => {
                        this.setState({
                            isCustomFieldPopupRequired: false,
                            customFieldPosition: undefined,
                            childCustomField: undefined,
                        })
                    }}
                    componentList={this.state.componentList}
                    data={this.state.documentData}
                    customFieldPosition={this.state.customFieldPosition}
                    childCustomField={this.state.childCustomField}
                />
            </div>
        );
    }

    renderAddressFormatPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <AddressFormatPopup
                    onCancelClicked={() => {
                        this.setState({
                            isAddressFormatRequired: false
                        })
                    }}
                    onSaveClicked={(data) => {
                        this.setState({
                            isAddressFormatRequired: false,
                            documentData: data
                        })
                    }}
                    addressType={this.state.addressType}
                    data={this.state.documentData}
                />
            </div>
        );
    }

    renderCheckTableFormatPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <ChechkTablePopup
                    onCancelClicked={() => {
                        this.setState({
                            isCheckTableFormatRequired: false
                        })
                    }}
                    onSaveClicked={(data) => {
                        this.setState({
                            isCheckTableFormatRequired: false,
                            documentData: data
                        })
                    }}
                    addressType={this.state.addressType}
                    data={this.state.documentData}
                />
            </div>
        );
    }

    renderDocumentDetailsPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <DocumentDetailsPopup
                    onCancelClicked={() => {
                        this.setState({
                            isDocumentPopupRequired: false
                        })
                    }}
                    onSaveClicked={(data) => {
                        this.setState({
                            isDocumentPopupRequired: false,
                            documentData: data
                        })
                    }}
                    data={this.state.documentData}
                />
            </div>
        );
    }

    renderTopSectionAboveTablePopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <TopSectionAboveTablePopup
                    onCancelClicked={() => {
                        this.setState({
                            isTopSectionAboveTablePopupRequired: false
                        })
                    }}
                    onSaveClicked={(data) => {
                        this.setState({
                            isTopSectionAboveTablePopupRequired: false,
                            documentData: data
                        })
                    }}
                    data={this.state.documentData}
                />
            </div>
        );
    }

    renderTableColumnAlignmentPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <TableColumnAlignmentPopup
                    onClosePressed={this.onSaveTemplatePopupClose}
                    documentType={this.state.documentData.type}
                    data={JSON.parse(JSON.stringify(this.state.tableColumnData))}
                    documentData={this.state.documentData}
                />
            </div>
        );
    }

    renderTableColumnVerticalAlignmentPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <TableColumnVerticalAlignmentPopup
                    onClosePressed={this.onSaveTemplatePopupClose}
                    documentType={this.state.documentData.type}
                    data={JSON.parse(JSON.stringify(this.state.tableColumnData))}
                    documentData={this.state.documentData}
                />
            </div>
        );
    }


    renderPayslipFieldConfigurationPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <PayslipFieldConfigurationPopup
                    onClosePressed={this.onPayslipConfigurationClosed}
                    data={this.state.documentData}
                    onSaveClicked={this.onPayslipConfigurationClosed}
                />
            </div>
        );
    }

    renderPayslipEarningsFieldConfigurationPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <PayslipEarningsFieldConfigurationPopup
                    onClosePressed={this.onPayslipEarningsConfigurationClosed}
                    data={this.state.documentData}
                    onSaveClicked={this.onPayslipEarningsConfigurationClosed}
                />
            </div>
        );
    }


    renderPayslipTableConfigurationPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <PayslipTableConfigurationPopup
                    onClosePressed={this.onPayslipConfigurationClosed}
                    data={this.state.documentData}
                    onSaveClicked={this.onPayslipConfigurationClosed}
                />
            </div>
        );
    }


    renderEmailPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <EmailComposer documentData={this.state.documentData}
                    removeEmailPopUp={this.removeEmailPopUp}
                    pdfURL={this.state.pdfURL}
                />
            </div>
        );
    }

    renderBOMComponentTablePopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <BOMComponentTablePopup
                    onClosePressed={this.onSaveTemplatePopupClose}
                    documentType={this.state.documentData.type}
                    data={JSON.parse(JSON.stringify(this.state.tableColumnData))}
                    documentData={this.state.documentData}
                />
            </div>
        );
    }

    renderHeaderMenu() {
        return (
            <HeaderMenu isFromERP={this.state.isFromERP}
                isFromSharing={this.state.isReadOnlyMode}
                isSavingTemplate={this.state.isSavingTemplate}
                generatingFile={this.state.generatingFile}
                isViewPdf={this.state.isViewPdf}
                isEmailPDF={this.state.isEmailPDF}
                isDownloadingPDF={this.state.isDownloadingPDF}
                isPrintingPDF={this.state.isPrintingPDF}
                isPreviewingPdf={this.state.isPreviewingPdf}
                sharableURL={this.state.sharableURL}
                currentLanguage={this.state.currentLanguage}
                currentDocumentType={this.state.documentData.type}
                currentDocumentCode={this.state.documentData.refNumber}
                currentDocumentData={this.state.documentData}
                isGeneratingShareableLink={this.state.isGeneratingShareableLink}
                onLanguageSelectionChanged={(selectedLanguage) => this.updateCurrentLanguage(selectedLanguage)}
                onLogo={this.logoTapped}
                onDeskeraSuitesOptionTapped={this.deskeraSuitesOptionTapped}
                onExport={this.exportTapped}
                onPdfExport={this.pdfExportTapped}
                onPrint={this.printTapped}
                onEmail={this.emailTapped}
                viewPdf={this.pdfViewTapped}
                onPreviewPdf={this.previewPdfTapped}
                onSave={(async () => { await this.saveTapped() })}
                isTappedOutside={this.state.isTappedOutside}
                userLoggedIn={this.state.userLoggedIn}
                isPreviewMode={this.state.isPreviewMode}
            />
        );
    }

    viewForWeb() {
        return (
            <div style={{ display: "flex", flex: 1, flexDirection: "row", width: "100%", height: "100%", overflow: "hidden", alignItems: 'center' }}>
                <div style={{ display: "flex", flex: 1, flexDirection: "row", width: "100%", height: "100%", overflow: "hidden", justifyContent: this.state.isReadOnlyMode ? 'center' : 'space-between' }}>
                    {!this.state.isReadOnlyMode && this.state.canShowPage && this.state.selectedComponent === undefined && this.getLeftPanel()}
                    {!this.state.isReadOnlyMode && this.state.selectedComponent !== undefined && this.getSettingPanel()}
                    {this.state.canShowPage && this.getDocumentTemplate()}
                    {!this.state.isReadOnlyMode && this.state.canShowPage && this.getThemeSelectionPanel()}
                    {!this.state.canShowPage && this.getLoadingMsg()}
                </div>
                {!this.state.isPreviewMode && this.state.isReadOnlyMode && ComponentManager.getDeskeraBadge()}
            </div>
        )
    }

    renderSignInPopup() {
        return (
            <SignInPopup documentData={this.state.documentData}
                documentType={this.state.documentData.type}
                onClose={this.onSignInPopupClosed}
                redirectionURL={this.state.sharableURL} />
        );
    }

    ///////////////////////////////////////////////////////////////
    ////////////   ViewForWeb Related Render Methods   ////////////
    ///////////////////////////////////////////////////////////////

    getLeftPanel() {
        return (
            <div style={{ backgroundColor: 'white', display: 'flex', zIndex: 10, width: EDITOR_WIDTH, opacity: this.state.needDeskeraSuitesPopup ? 1 : 0.3 }}
                onClick={() => {
                    if (this.state.isAddComponentPopupRequired) {
                        this.setState({ isAddComponentPopupRequired: !this.state.isAddComponentPopupRequired })
                    }
                }}
            >
                <div className="EditorHolder" style={{ width: '100%' }}>
                    {
                        <DocumentEditor
                            isFromERP={this.state.isFromERP}
                            data={this.state.documentData}
                            onDataChanged={this.onDataChanged}
                            onDataTypeChanged={this.onDataTypeChanged}
                            onImageTapped={this.showImagePopup}
                            selectedImage={this.state.imagePopupSelectedImage}
                            onEditTableTapped={this.openEditTablePopup}
                            onAddPopupTapped={() => {
                                this.setState({
                                    isAddComponentPopupRequired: !this.state.isAddComponentPopupRequired,
                                    isViewComponentPopupRequired: false
                                })
                            }}
                            isAddComponentPopupRequired={this.state.isAddComponentPopupRequired}
                            onViewPopupTapped={() => {
                                this.setState({
                                    isViewComponentPopupRequired: !this.state.isViewComponentPopupRequired,
                                    isAddComponentPopupRequired: false,
                                })
                            }}
                            isViewComponentPopupRequired={this.state.isViewComponentPopupRequired}
                            onSelectComponent={(component) => {
                                if (component.id !== undefined && component.id !== null) {
                                    this.setState({
                                        id: component.id,
                                        selectedComponent: component,
                                        isViewComponentPopupRequired: !this.state.isViewComponentPopupRequired,
                                    })
                                }
                            }}
                            onRemoveComponent={(component, view) => this.onRemoveComponent(component, view)}
                            screenState={ScreenState.CREATE}
                            id={this.state.id}
                            componentList={this.state.componentList}
                            onAddComponentTapped={(selected, componentList, id, action) => {
                                this.setState({
                                    selectedComponent: selected,
                                    doubleClickComponent: undefined,
                                    componentList: componentList,
                                    id: id,
                                    isAddComponentPopupRequired: !this.state.isAddComponentPopupRequired,
                                    isViewComponentPopupRequired: false
                                }, () => {
                                    TemplateSettingsManager.updateComponentList(this.state.componentList)
                                })
                            }}
                            onCancelPressed={() => {
                                this.setState({
                                    isAddComponentPopupRequired: false,
                                    isViewComponentPopupRequired: false
                                })
                            }}
                            onSelectCustomField={() => {
                                if(CustomFieldManager.getIsContainsRenderSystemField()) {
                                    this.setState({
                                        isAddComponentPopupRequired: !this.state.isAddComponentPopupRequired,
                                        isCustomFieldPopupRequired: true,
                                        customFieldPosition: 'top',
                                        isViewComponentPopupRequired: false
                                    })
                                }
                                else {
                                    this.setState({
                                        isEmptyCustomFieldPopupRequired: true,
                                    })
                                }
                            }}
                            onHeaderFooterClick={() => {
                                this.setState({
                                    isHeaderFooterPopupRequired: true
                                })
                            }}
                            onCheckTableFormatClick={(type) => {
                                this.setState({
                                    isCheckTableFormatRequired: true
                                })
                            }}
                            onAddressFormatClick={(type) => {
                                this.setState({
                                    isAddressFormatRequired: true,
                                    addressType: type
                                })
                            }}
                            onEditButtonCallbackClicked={(type) => {
                                if(type === EditorInfoType.additionalLeftFooterTable) {
                                    this.setState({
                                        isAdditionalTablePopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.watermark) {
                                    this.setState({
                                        isWatermarkRequiredPopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.topCustomFieldTable) {
                                    this.setState({
                                        isCustomFieldPopupRequired: true,
                                        customFieldPosition: 'top'
                                    })
                                }
                                else if (type === EditorInfoType.bottomCustomFieldTable) {
                                    this.setState({
                                        isCustomFieldPopupRequired: true,
                                        customFieldPosition: 'bottom'
                                    })
                                }
                                else if (type === EditorInfoType.childCustomFieldTable) {
                                    this.setState({
                                        isCustomFieldPopupRequired: true,
                                        childCustomField: true
                                    })
                                }
                                else if (type === EditorInfoType.topSectionAboveTable) {
                                    this.setState({
                                        isTopSectionAboveTablePopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.tableColumnAlignment) {
                                    this.setState({
                                        isTableColumnAlignmentPopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.tableColumnVerticalAlignment) {
                                    this.setState({
                                        isTableColumnVerticalAlignmentPopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.documentLabel) {
                                    this.setState({
                                        isDocumentLabelPopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.isIncludesBomProductTable) {
                                    this.setState({
                                        isBOMComponentTablePopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.pageCountSettings) {
                                    this.setState({
                                        isPageCountPopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.approvalSection) {
                                    this.setState({
                                        isApprovalSectionPopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.customApprovalSection) {
                                    this.setState({
                                        isCustomApprovalSectionPopupRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.paperMargin) {
                                    this.setState({
                                        isPaperMarginRequired: true
                                    })
                                }
                                else if (type === EditorInfoType.draftWatermark) {
                                    this.setState({
                                        isDraftWatermarkPopupRequired: true
                                    })
                                }
                            }}
                            onPayslipConfigurationClick={(editorType) => {
                                var isPayslipFieldPopupRequired = this.state.isPayslipFieldPopupRequired
                                var isPayslipTablePopupRequired = this.state.isPayslipTablePopupRequired
                                if(editorType === EditorInfoType.payslipField) {
                                    isPayslipFieldPopupRequired = true
                                }
                                else if (editorType === EditorInfoType.payslipTable) {
                                    isPayslipTablePopupRequired = true
                                }
                                this.setState({
                                    isPayslipFieldPopupRequired: isPayslipFieldPopupRequired,
                                    isPayslipTablePopupRequired: isPayslipTablePopupRequired
                                })
                            }}
                            onEarningsConfigurationClick={(editorType) => {
                                var isPayslipEarningsPopupRequired = this.state.isPayslipEarningsPopupRequired;
                                if(editorType === EditorInfoType.payslipEarningsFields) {
                                    isPayslipEarningsPopupRequired = true
                                }
                                
                                this.setState({
                                    isPayslipEarningsPopupRequired: isPayslipEarningsPopupRequired
                                })
                            }}
                            onDocumentDetailsClick={() => {
                                this.setState({
                                    isDocumentPopupRequired: true
                                })
                            }}
                            onChequeDetailsClick={() => {
                                this.setState({
                                    isChequePopupRequired: true
                                })
                            }}
                        />
                    }
                </div>
            </div>
        );
    }

    getSettingPanel() {
        return (
            <div style={{ backgroundColor: 'white', display: 'flex', zIndex: 10, width: EDITOR_WIDTH, opacity: this.state.needDeskeraSuitesPopup ? 1 : 0.3 }}
                onClick={() => {
                    if (this.state.isAddComponentPopupRequired) {
                        this.setState({ isAddComponentPopupRequired: !this.state.isAddComponentPopupRequired })
                    }
                    else if (this.state.isViewComponentPopupRequired && this.view === '') {
                        this.setState({ isViewComponentPopupRequired: !this.state.isViewComponentPopupRequired })
                    }
                }}>
                <div className="EditorHolder" style={{ width: '100%' }}>
                    {this.state.selectedComponent !== undefined && this.renderSettingPanel()}
                </div>
            </div>
        );
    }

    getDocumentTemplate() {
        return (
            <div
                id={HTMLTag.MAIN_TEMPLATE}
                style={{
                    overflowY: "scroll",
                    justifyContent: "center",
                    backgroundColor: 'rgb(240, 240, 240)',
                    boxSizing: "border-box",
                }}
                onClick={() => {
                    this.setState({ isTappedOutside: true });
                    setTimeout(() => {
                        this.setState({ isTappedOutside: false, needTemplateSelectionPopup: true, needDeskeraSuitesPopup: true });
                    }, 100);
                }}
            >
                <iframe
                    title=' '
                    id={HTMLTag.PRINT_HTML}
                    style={{
                        height: 0,
                        width: 0,
                        position: 'absolute',
                        opacity: 0,
                        backgroundColor: 'white'
                    }}
                />
                {this.renderDocumentData()}
            </div>
        );
    }

    renderDocumentData() {
        if (TemplateSettingsManager.getIsMultipleDocument()) {
            if (this.state.documentDataList !== undefined && this.state.documentDataList !== null) {
                if (this.state.documentDataList.length > 0) {
                    return this.state.documentDataList.map(element => {
                        return this.getOnlyTemplate(element)
                    });
                }
            }
        }

        return this.getOnlyTemplate()
    }

    getOnlyTemplate(documentData) {
        // TODO: DATA DOCUMENT DATA IS SETTINGUP HERE
        var data = this.state.documentData
        if(documentData !== undefined) {
            data = documentData
        }

        let isCustomMargin = false 
        if (TemplateSettingsManager.getPrintInfo() !== undefined) {
            isCustomMargin = TemplateSettingsManager.getPaperMargin()?.isCustomMargin ?? false
        }

        return <div className={this.state.printInfo === undefined ? "PageHolder" : ''}
            id={HTMLTag.TEMPLATE}
            style={{
                position: 'relative',
                width: getPageWidth(),
                borderRadius: 4,
                backgroundColor: 'white',
                paddingTop: isCustomMargin ? undefined : this.getPaddingTop(),
                paddingBottom: isCustomMargin ? undefined : this.getPaddingBottom(),
                paddingLeft: isCustomMargin ? undefined : getVW(PAGE_PADDING, true),
                paddingRight: isCustomMargin ? undefined : getVW(PAGE_PADDING, true),
                overflow: 'hidden',
                marginBottom: this.getMarginBottom(),
                minHeight: this.state.printInfo === undefined ? getPageHeight() : undefined,
            }}>
            <div>
                {this.renderDocumentDesignerManager()}
            </div>
            <div id={HTMLTag.PAGE_COUNT_TOP}>
            </div>
            <div
                id={HTMLTag.ONLY_TEMPLATE}
                style={{
                    width: '100%',
                    minHeight: getPageHeight(),
                }}
                onClick={() => {
                    this.setState({
                        selectedComponent: undefined,
                        doubleClickComponent: undefined,
                        isAddComponentPopupRequired: false,
                        isViewComponentPopupRequired: false,
                    })
                }}>
                    <DraftWatermarkManager 
                        mainContent={this.getHeaderFooterSection(data)}
                        data={data}
                    />
                
            </div>
        </div>
    }

    getHeaderFooterSection(documentData) {
        return <HeaderFooterManager
            mainContent={this.getWatermarkSection(documentData)}
            isReadOnlyMode={this.state.printInfo !== undefined}
            documentData={documentData}
            onHeaderFooterClick={(type) => {
                this.setState({
                    isHeaderFooterPopupRequired: true
                })
            }}
        />
    }

    getWatermarkSection(documentData) {
        return <WatermarkManager
            mainContent={this.getDocumentLabelSection(documentData)}
            onWatermarkClick={(type) => {
                this.setState({
                    isWatermarkRequiredPopupRequired: true
                })
            }}
        />
    }

    getDocumentLabelSection(documentData) {
        return <DocumentLabelManager 
            mainContent={this.getDocumentOrLabelTemplate(documentData)}
            documentData={documentData}
            onWatermarkClick={(type) => {
                this.setState({
                    isDocumentLabelPopupRequired: true
                })
            }}
        />
    }


    getPaddingTop(){
        if (this.state.printInfo !== undefined) {
            return undefined
        }
        else {
            return getVW(PAGE_PADDING, true)
        }
    }

    getPaddingBottom() {
        if (this.state.printInfo !== undefined) {
            return MultiPageManager.getPaddingBottom(this.isLoaded, this.state.printInfo)
            // if (this.state.printInfo.tId !== undefined) {
            //  return MultiPageManager.getPaddingBottom(this.isLoaded, this.state.printInfo)
            // }
            // else {
            //     return getVW(PAGE_PADDING, true)
            // }
        }
        else {
            return getVW(PAGE_PADDING, true)
        }
    }

    getMarginBottom() {
        if (this.state.printInfo !== undefined) {
            if (this.state.printInfo.tId !== undefined) {
                return 0
            }
            else {
                var isMultiplePage = AppManager.getIsMultiplePage()
                if (isMultiplePage !== undefined && isMultiplePage !== null) {
                    return 0
                }
                return getVW(NEGATIVE_PAGE_PADDING, true)
            }
        }
        else {
            return 0
        }
    }

    getDocumentOrLabelTemplate(documentData) {
        var type = documentData ? documentData.type : 'invoice'
        if (TemplateManager.getIsLabelType(type)) {
            return DocumentManager.getLabelTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsPayslip(type)) {
            return DocumentManager.getPayslipTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsCheque(type)) {
            return DocumentManager.getChequeTemplateWith(
                this.state.templateId,
                documentData,
            )
        }
        else if (Utility.getIsReceiptPaperSize()) {
            return DocumentManager.getReceiptTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (data) => {
                    this.setState({ isAdditionalTablePopupRequired: true })
                },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsPayrollCheck(type)) {
            return DocumentManager.getPayrollCheckTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsEwayBillDetails(type)) {
            return DocumentManager.getEWayBillDetailsTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsEwayBillSummary(type)) {
            return DocumentManager.getEWayBillSummaryTemplateWith(this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsWorkOrder(type)) {
            return DocumentManager.getWorkOrderTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (data) => {
                    this.setState({ isAdditionalTablePopupRequired: true })
                },
                (position, childCustomField) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        // else if (getIsJobWorkOutOrder(type)) {
        //     return DocumentManager.getJobWorkOutOrderTemplateWith(
        //         this.state.templateId,
        //         documentData,
        //         this.state.tableColumnData,
        //         () => { this.tableClicked() },
        //         () => { this.stockTableClicked() },
        //         (data) => {
        //             this.setState({ documentData: data })
        //         },
        //         () => { this.paymentTableClicked() },
        //         (position) => {
        //             this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
        //         }
        //     )
        // }
        else if (getIsJobCard(type)) {
            return DocumentManager.getJobCardTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        
        else if (getIsMachine(type)){
            return DocumentManager.getMachineSchedulerTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsQCDocument(type)) {
            return DocumentManager.getQCTemplateWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.tableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (position) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsQCInspection(type)) {
            return DocumentManager.getQCInspectionWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.qcInspectionTableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (data) => {
                    this.setState({ isAdditionalTablePopupRequired: true })
                },
                (position, childCustomField) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        else if (getIsInspectionReport(type)) {
            return DocumentManager.getInspectionReportWith(
                this.state.templateId,
                documentData,
                this.state.tableColumnData,
                () => { this.qcInspectionTableClicked() },
                () => { this.stockTableClicked() },
                (data) => {
                    this.setState({ documentData: data })
                },
                () => { this.paymentTableClicked() },
                (data) => {
                    this.setState({ isAdditionalTablePopupRequired: true })
                },
                (position, childCustomField) => {
                    this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position })
                }
            )
        }
        return DocumentManager.getTemplateWith(
            this.state.templateId,
            documentData,
            this.state.tableColumnData,
            (tableType) => { this.tableClicked(tableType) },
            () => { this.stockTableClicked() },
            (data) => {
                this.setState({ documentData: data })
            },
            () => { this.paymentTableClicked() },
            (data) => {
                this.setState({ isAdditionalTablePopupRequired: true })
            },
            (position, childCustomField) => {
                this.setState({ isCustomFieldPopupRequired: true, customFieldPosition: position, childCustomField: childCustomField })
            }
        )
    }


    getLoadingMsg(message = null) {
        return (
            <div className="ColumnDiv" style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', backgroundColor:'rgb(240, 240, 240)', top: 0, left: 0, zIndex:9999 }}>
                <text style={{ fontSize: getFontSize(FONT_SIZE.medium), fontWeight: '600'}}>{getLocalisedText('loading_the_page')}</text>
                {ComponentManager.addVerticalSpace(6)}
                <text style={{ fontSize: getFontSize(FONT_SIZE.regular), color: 'rgb(100, 100, 100)' }}>{getLocalisedText(message ? message : 'please_wait')}</text>
            </div>
        )
    }

    getThemeSelectionPanel() {
        return <div style={{ backgroundColor: 'white', display: 'flex', width: "15%", opacity: this.state.needTemplateSelectionPopup ? 1 : 0.3 }}>
            <TemplatesListScreen
                selectedTemplateID={parseInt(this.state.templateId)}
                onTemplateSelect={this.templateSelected}
                templatesData={TemplateManager.getAllTemplates(this.state.documentData.type, this.state.documentData)}
                isFromERP={this.state.isFromERP}
                onClose={() => {
                }}
                onCustomTemplateTapped={(customTemplate) => {
                    this.templateSettingsResponse(customTemplate, this.fromERPData)
                }}
                onAddCustomTemplateTapped={() => {
                    TemplateSettingsManager.updateRemoteTemplateUID(undefined)
                    TemplateSettingsManager.setCustomTemplateName('New Template')
                    var data = this.state.documentData
                    var decimal = this.fromERPData.decimalScale ? this.fromERPData.decimalScale : 2
                    data.componentList = []
                    data.themeColor = 'black'
                    AppManager.setDecimalScale(decimal)
                    TemplateSettingsManager.updateDecimalScale(decimal)
                    this.setState({
                        templateId: 1,
                        documentData: data,
                        componentList: [],
                        id: 0,
                    })
                }}
                deleteTemplateItem={(template) => {
                    this.setState({
                        isDeleteTemplateRequired: true,
                        deleteTemplateItem: template
                    })
                }}
            />
        </div>
    }

    renderDocumentDesignerManager() {
        return (
            <DocumentDesignerManager
                componentList={this.state.componentList}
                selectedComponent={this.state.selectedComponent}
                globalSetting={this.state.globalSetting}
                showGrid={this.state.showGrid}
                isDragging={this.state.isDragging}
                id={this.state.id}
                doubleClickComponent={this.state.doubleClickComponent}
                onDoubleClickComponent={(selected) => {
                    this.setState({
                        doubleClickComponent: selected
                    }, () => {
                        this.onDoubleClickComponent()
                    })
                }}
                onUpdateComponent={(selected, componentList) => {
                    this.setState({
                        selectedComponent: selected,
                        componentList: componentList
                    }, () => {
                        TemplateSettingsManager.updateComponentList(this.state.componentList)
                    })
                }}
            />
        )
    }

    ///////////////////////////////////////////////////////////////
    //////////  getSettingPanel Related Render Methods   //////////
    ///////////////////////////////////////////////////////////////

    renderSettingPanel() {
        return (
            <SettingPanel
                id={this.state.id}
                componentList={this.state.componentList}
                screenState={this.state.screenState}
                templateItem={this.state.selectedComponent}
                onComponentDataChanged={(component) => this.onUpdateComponent(component)}
                data={this.state.documentData}
                onImageTapped={() => this.setState({
                    isImagePopupRequired: true
                })}
                onRemoveComponent={(component, view) => this.onRemoveComponent(component, view)}
                onDuplicateComponent={(component) => this.onDuplicateComponent(component)}
                onAddComponentTapped={(action) => this.onAddComponentTapped(action)}
                onCancelPressed={() => {
                    this.setState({
                        isAddComponentPopupRequired: false,
                        isViewComponentPopupRequired: false
                    })
                }}
                onAddComponent={(selected, componentList, id, action) => {
                    this.setState({
                        selectedComponent: selected,
                        doubleClickComponent: undefined,
                        componentList: componentList,
                        id: id,
                        isAddComponentPopupRequired: !this.state.isAddComponentPopupRequired,
                        isViewComponentPopupRequired: false
                    })
                }}
                isAddComponentPopupRequired={this.state.isAddComponentPopupRequired}
                onAddPopupTapped={() => {
                    this.setState({
                        isAddComponentPopupRequired: !this.state.isAddComponentPopupRequired,
                        isSharePopupRequired: false,
                        isShowEditDocumentDesigner: false,
                        isTemplatePopupRequired: false,
                        isImagePopupRequired: false,
                        isViewComponentPopupRequired: false
                    })
                }}
                isViewComponentPopupRequired={this.state.isViewComponentPopupRequired}
                onViewPopupTapped={() => {
                    this.setState({
                        isAddComponentPopupRequired: false,
                        isSharePopupRequired: false,
                        isShowEditDocumentDesigner: false,
                        isTemplatePopupRequired: false,
                        isImagePopupRequired: false,
                        isViewComponentPopupRequired: !this.state.isViewComponentPopupRequired,
                    })
                }}
                onSelectComponent={(component)=> {
                    if(component.id !== undefined && component.id !== null) {
                        this.setState({
                            id: component.id,
                            selectedComponent: component,
                            isViewComponentPopupRequired: !this.state.isViewComponentPopupRequired,
                        })
                    }
                }}
                onSelectSystemField={() => {
                    this.setState({
                        isCustomFieldPopupRequired: !this.state.isCustomFieldPopupRequired
                    })
                }}
            />
        )
    }

    ///////////////////////////////////////////////////////////////
    ////////////////   getParamVariables Method   /////////////////
    ///////////////////////////////////////////////////////////////
    getIsContainPrintParamFromUrl() {
        let parts = window.location.search.substr(1).split("&"); //window.location.search
        let $_GET = {};
        for (let i = 0; i < parts.length; i++) {
            let temp = parts[i].split("=");
            $_GET[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
        }
        let param_pid = $_GET["pid"];
        if(param_pid !== undefined && param_pid !== null && param_pid !== '') {
            return true
        }

        return false
    }


    getParamVariables(isFromLocalStore = false, savedDocumentDataValue = null) {
        let webURL = window.location.href

        let lastSlashIndex = webURL.lastIndexOf('/');
        let result = webURL.substring(lastSlashIndex + 1)

        let fromERP = result.length > 2
        /////////////////////////////////////////////////////////////////
        let parts = window.location.search.substr(1).split("&"); //window.location.search
        /////////////////////////////////////////////////////////////////

        let $_GET = {};
        for (let i = 0; i < parts.length; i++) {
            let temp = parts[i].split("=");
            $_GET[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
        }

        let type = "invoice";
        let param_type = $_GET["type"];
        let param_templateId = $_GET["templateid"];
        let param_sharing = $_GET["sharing"];
        let param_share = $_GET["share"];
        let param_downloadPdf = $_GET["downloadpdf"];
        let param_viewPdf = $_GET["viewpdf"];
        let param_emailPdf = $_GET["emailpdf"];
        let param_printPdf = $_GET["printpdf"];
        let param_myTemplateId = $_GET["mytemplateid"];
        let param_preview = $_GET["preview"];
        let param_pid = $_GET["pid"];
        let param_tid = $_GET["tid"];
        let param_paperSize = $_GET["papersize"];
        let param_data = savedDocumentDataValue !== null && savedDocumentDataValue !== 'null' ? savedDocumentDataValue : $_GET["dt"];
        let param_isMultiplePage = $_GET["multi"];
        let param_wmark = $_GET["wmark"];
        let param_documentType = $_GET["docType"];

        if (param_type !== undefined) {
            type = param_type
            param_type = param_type.charAt(0) + param_type.slice(1);
            if (param_type.toLowerCase() !== "invoice" && param_type.toLowerCase() !== "estimate" && param_type.toLowerCase() !== "quotation" && param_type.toLowerCase() !== "order" && param_type.toLowerCase() !== "bill" && param_type.toLowerCase() !== "payslip" && param_type.toLowerCase() !== "payroll_check") {
                param_type = "invoice"
            }
            fromERP = false
        } else {
            param_type = type
        }
        if (param_templateId === undefined) {
            param_templateId = TemplateManager.getInitTemplate(param_type.toLowerCase());
        } else {
            // fromERP = false
            if (param_templateId > TemplateManager.getAllTemplates(param_type, undefined).length) {
                param_templateId = TemplateManager.getInitTemplate(param_type.toLowerCase());
            }
            this.setState({templateIdFromParam: Number(param_templateId)})
        }

        if(param_documentType !== undefined) {
            var documentType = NewDataParser.getDocumentTemplateType(param_documentType)
            this.documentType = documentType
            param_templateId = TemplateManager.getInitTemplate(documentType.toLowerCase());
            let designInfo = {
                tId: param_tid,
                documentType: documentType,
            }
            AppManager.setDesignInfo(designInfo)
            if(param_tid !== undefined) {
                TemplateSettingsManager.updateRemoteTemplateUID(param_tid)
            }
            else {
                TemplateSettingsManager.updateRemoteTemplateUID(undefined)
            }
        }

        /////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////
        let templateData = null

        if (param_documentType !== undefined) {
            templateData = this.getTemplateData(
                NewDataParser.getDocumentTemplateType(param_documentType).toLowerCase(),
                parseInt(param_templateId)
            )
            fromERP = false
        }
        else {
            templateData = this.getTemplateData(
                param_type,
                parseInt(param_templateId)
            )
        }

        if(getIsPayslip(param_type)) {
            PayslipUtility.resetMockData(templateData, parseInt(param_templateId))
        } else if(getIsPayrollCheck(param_type)) {
            PayrollCheckUtility.resetMockData(templateData, parseInt(param_templateId))
        }
        /////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////
        if (param_data !== undefined) {
            let decodedJSON = Utility.decodeString(param_data)
            templateData = decodedJSON
            param_type = templateData.type
            param_templateId = parseInt(templateData.templateId)
            fromERP = false
            if (!isFromLocalStore) {
                this.setState({
                    isReadOnlyMode: true
                })
            }
        }
        /////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////
        let isGclidIndex = webURL.lastIndexOf('gclid');
        if (isGclidIndex > -1) {
            fromERP = false
            let parts = webURL.substr(isGclidIndex).split("=");
            if (parts.length > 1) {
                let gclidValue1 = parts[1].split("&")[0]
                let gclidValue2 = gclidValue1.split('/')[0]
                let gclidValue3 = gclidValue2.split('?')[0]
                AppManager.setGclidValue(gclidValue3)
            }
        }

        var printInfo = undefined
        var mutliWmark = undefined
        if(param_wmark !== undefined && param_wmark !== null) {
            if(param_wmark.length > 0) {
                param_wmark = param_wmark.toLowerCase()

                if(param_wmark.includes(',')) {
                    var wmarkList = param_wmark.split(',').filter(x => x !== '')
                    if(wmarkList.includes('all')) {
                        param_wmark = 'all'
                    }
                    else {
                        wmarkList = wmarkList.filter(x => x !== 'all')
                        if (wmarkList.length > 1) {
                            mutliWmark = wmarkList
                            param_wmark = 'all'
                        }
                        else {
                            param_wmark = wmarkList[0]
                        }
                    }

                }
            }
        }

        if(param_pid !== undefined) {
            printInfo = {
                tId: param_tid,
                id: param_pid.split('\\').join('').split('"').join(''),
                wmark: param_wmark,
                mutliWmark: mutliWmark
            }
        }

        if (param_myTemplateId !== undefined) {
            let customTemplateId = parseInt(param_myTemplateId)
            if(!isNaN(customTemplateId)) {
                TemplateSettingsManager.updateRemoteTemplateUID(customTemplateId)
            }
        }

        ///////////////////////////////////////////////////////////////
        /////////////////////// CHECK IF FROM ERP /////////////////////
        ///////////////////////////////////////////////////////////////
        if (fromERP) {
            let isSharingMode = Utility.getURLOptionValue(param_sharing)
            let isShareMode = Utility.getURLOptionValue(param_share)
            let isDownloadMode = Utility.getURLOptionValue(param_downloadPdf)
            let isViewMode = Utility.getURLOptionValue(param_viewPdf)
            let isPrintMode = Utility.getURLOptionValue(param_printPdf)
            let isEmailMode = Utility.getURLOptionValue(param_emailPdf)
            let isPreviewMode = Utility.getURLOptionValue(param_preview)
            AppManager.setIsMultiplePage(param_isMultiplePage)
            if (param_paperSize !== undefined) {
                if (param_paperSize === 'three_five' || param_paperSize === 'four_six') {
                    TemplateSettingsManager.setPageFormat(param_paperSize)
                }
            }
            this.setState({
                isFromERP: true,
                isReadOnlyMode: isSharingMode || isShareMode || isPreviewMode || printInfo !== undefined,
                isDownloadingPDF: isDownloadMode,
                isViewPdf: isViewMode,
                isPrintingPDF: isPrintMode,
                isEmailPDF: isEmailMode,
                generatingFile: isEmailMode,
                bypassUserLoginCheck: isDownloadMode || isPrintMode || isEmailMode || isViewMode,
                isPreviewMode: isPreviewMode || printInfo !== undefined,
                printInfo: printInfo,
                isSharingMode: isSharingMode
            })
            this.getDocumentDetails(result, isShareMode, isSharingMode, printInfo)
            AppManager.setDocumentId(result)
        } else {
            setTimeout(() => {
                this.setState({ isFromERP: false, isReadOnlyMode: false })
                var type = param_type 
                if(AppManager.getDesignInfo() !== undefined) {
                    type = AppManager.getDesignInfo().documentType
                }
                let templateId = TemplateSettingsManager.defaultTemplateSettings.templateInfo.id
                templateData['isReadOnlyMode'] = this.state.isReadOnlyMode
                this.setData(templateId, type, templateData)
                if (this.state.userLoggedIn && AppManager.getDocumentId() === undefined) {
                    ApiManager.getTemplateSettingsFromRemote(TemplateSettingsManager.getTemplateNameType(type.toLowerCase()), this, this.fromERPData)
                }
            }, 100)

        }
    }

    ///////////////////////////////////////////////////////////////
    //////////   Template Creation Related Methods   ///////////
    ///////////////////////////////////////////////////////////////

    setData(templateId, templateType, documentData, documentDataList) {
        this.setState({
            templateId: parseInt(templateId),
            templateType: templateType,
            documentData: documentData,
            tableColumnData: documentData.tableColumnPopup,
            componentList: documentData.componentList,
            currentLanguage: documentData.languageInfo,
            canShowPage: true,
            id: TemplateSettingsManager.getComponentMaxId(),
            documentDataList: documentDataList,
        },
            () => {
                DocumentManager.setCurrentData(this.state.documentData)
                if (this.state.printInfo !== undefined) {
                    if(getIsPayslip(templateType)) {
                        document.title = templateType.toUpperCase()
                    }
                    else {
                        let refNumber = documentData.refNumber ?? ''
                        document.title = templateType.toUpperCase()

                        if (refNumber !== '') {
                            document.title = templateType.toUpperCase() + ' - ' + refNumber
                        }
                    }
                }

                // Add document type specific table info
                this.updateTableInfo(templateType);
            }
        );
    }

    getTemplateData(type, id) {
        return DocumentManager.getTemplateData(type, id)
    }

    ///////////////////////////////////////////////////////////////
    /////////////   SignInPopup Related Methods   /////////////////
    ///////////////////////////////////////////////////////////////

    showSignInPopup = () => {
        this.setState({
            sharableURL: Utility.getSharableURL(this.state.documentData),
            needSignInPopup: true
        })
    }

    hideSignInPopup = () => {
        this.setState({
            needSignInPopup: false
        })
    }

    onSignInPopupClosed = () => {
        // ApiManager.checkIfUserLoggedIn(this)
        this.hideSignInPopup()
    }

    ///////////////////////////////////////////////////////////////
    ////////////   All Component Callback Methods   ///////////////
    ///////////////////////////////////////////////////////////////

    ////////////   DocumentEditor Callbacks   ////////////////
    onDataChanged = (data) => {
        if(data.event === undefined) {
            this.resetDefaultData(data)
        }
        else if(data.event === 'onPaperSizeChanged') {
            data.event = undefined
            TemplateSettingsManager.updateTablePopupInfo(data.tableColumnPopup)
            this.setState({
                tableColumnData: data.tableColumnPopup,
            }, () => {
                this.resetDefaultData(data)
            })
        }
    };

    onDataTypeChanged = (type) => {
        DocumentManager.setCurrentData(null)
        let templateData = this.getTemplateData(type.toLowerCase(), 1)
        templateData['isReadOnlyMode'] = this.state.isReadOnlyMode
        this.documentType = type
        this.setData(1, type.toLowerCase(), templateData)
        if (this.state.userLoggedIn && AppManager.getDocumentId() === undefined) {
            ApiManager.getTemplateSettingsFromRemote(TemplateSettingsManager.getTemplateNameType(type.toLowerCase()), this, this.fromERPData)
        }
    }

    openEditTablePopup = () => {
        this.tableClicked()
    }

    ////////////   TemplatesListScreen Callbacks   ////////////////
    templateSelected = (id) => {
        var templateType = this.state.templateType
        let documentData = this.getTemplateData(templateType, id)
        TemplateSettingsManager.updateTemplateInfo(documentData.templateId, documentData.themeColor)
        documentData = IndiaTaxParser.updateTableStructure(documentData, id)
        documentData = PayslipUtility.resetMockData(documentData, id)
        if(Utility.getIsReceiptPaperSize()) {
            documentData = TemplateSettingsManager.resetDefaultDataForReceipt(documentData)
        }

        this.setState({
            templateId: id,
            documentData: documentData,
        });
    };

    ////////////   EmailComposer Callbacks   ////////////////
    removeEmailPopUp = () => {
        this.setState({
            showMailComposer: false
        })
    };

    ////////////   HeaderMenu Callbacks   ////////////////
    updateCurrentLanguage = (languageReceived) => {
        TemplateSettingsManager.updateCurrentLanguage(languageReceived)

        let dataToUpdate = this.state.documentData;
        dataToUpdate["documentType"] = this.state.documentData.type;

        this.setState({
            documentData: dataToUpdate,
            currentLanguage: languageReceived,
            selectedComponent: undefined,
            doubleClickComponent: undefined,
        }, () => {
            this.onDataChanged(dataToUpdate)
        })
    }

    logoTapped = () => {
        this.setState({
            needDeskeraSuitesPopup: !this.state.needDeskeraSuitesPopup,
            needTemplateSelectionPopup: true
        })
    }

    deskeraSuitesOptionTapped = () => {
        this.setState({
            needDeskeraSuitesPopup: !this.state.needDeskeraSuitesPopup,
            needTemplateSelectionPopup: true
        })
    }

    exportTapped = () => {
        this.setState({
            sharableURL: Utility.getSharableURL(this.state.documentData),
            needTemplateSelectionPopup: !this.state.needTemplateSelectionPopup,
            needDeskeraSuitesPopup: true
        })
    };

    pdfExportTapped = () => {
        this.onPrintOrExportPdf(ExportType.DOWNLOAD_PDF)
        // if (getPaperFormat() == null) {
        //     this.setState({
        //         isPaperFormatPopupRequired: true,
        //         exportType: ExportType.DOWNLOAD_PDF
        //     })
        // }
        // else {
        //     this.onPrintOrExportPdf(ExportType.DOWNLOAD_PDF)
        // }
    }

    pdfViewTapped = () => {
        this.onPrintOrExportPdf(ExportType.VIEW_PDF)
        // if (getPaperFormat() == null) {
        //     this.setState({
        //         isPaperFormatPopupRequired: true,
        //         exportType: ExportType.VIEW_PDF
        //     })
        // }
        // else {
        //     this.onPrintOrExportPdf(ExportType.VIEW_PDF)
        // }
    }

    previewPdfTapped = () => {
        this.onPrintOrExportPdf(ExportType.PREVIEW_PDF)
    }

    printTapped = () => {
        this.onPrintOrExportPdf(ExportType.PRINT)
    }

    emailTapped = () => {
        this.onPrintOrExportPdf(ExportType.EMAIL)
        // if (getPaperFormat() == null) {
        //     this.setState({
        //         isPaperFormatPopupRequired: true,
        //         exportType: ExportType.EMAIL
        //     })
        // }
        // else {
        //     this.onPrintOrExportPdf(ExportType.EMAIL)
        // }
    }

    async saveTapped() {
        if (!AppManager.isUserLoggedIn()) {
            this.showSignInPopup()
            return
        }
        this.setState({
            isSaveTemplatePopupRequired: true,
        })
        // this.setState({ isSavingTemplate: true }, () => {
        //     this.saveTemplateSettings(null)
        // })
    };

    onPrintOrExportPdf(type) {
        this.setState({
            selectedComponent: undefined,
            doubleClickComponent: undefined,
        }, () => {
            this.checkIfUserLoggedInBeforeExport(type)
        })
    }

    ////////////   DocumentDesignerManager Callbacks   ////////////////
    onDoubleClickComponent() {
        if (this.state.doubleClickComponent === undefined || this.state.doubleClickComponent === null) {
            return
        }
        if (this.state.doubleClickComponent.type === ComponentType.DKImage) {
            this.setState({
                isImagePopupRequired: !this.state.isImagePopupRequired
            })
        } else if (this.state.doubleClickComponent.type === ComponentType.DKLabel) {

        }
        return
    }

    ////////////   Settings Panel Callbacks   ////////////////
    onUpdateComponent(component) {
        let newComponentList = this.state.componentList
        let list = this.state.componentList.filter(x => x.id === component.id)
        if (list.length > 0) {
            let item = list[0]
            let index = this.state.componentList.indexOf(item)
            newComponentList[index] = component
            this.setState({
                componentList: newComponentList,
            }, () => {
                TemplateSettingsManager.updateComponentList(this.state.componentList)
            })
        }
    }

    onRemoveComponent(component, view) {
        this.view = view
        let newComponentList = this.state.componentList.filter(x => x.id !== component.id)
        var isViewComponentPopupRequired = this.state.isViewComponentPopupRequired
        if (view === 'ViewComponentListPopup') {
            isViewComponentPopupRequired = newComponentList.length > 0
        }
        this.setState({
            componentList: newComponentList,
            isViewComponentPopupRequired: isViewComponentPopupRequired,
            selectedComponent: undefined,
            doubleClickComponent: undefined,
        }, () => {
            TemplateSettingsManager.updateComponentList(this.state.componentList)
        })
    }

    onDuplicateComponent(component) {
        let newComponentList = this.state.componentList
        let newId = this.state.id + 1
        let newComponent = {}
        Object.assign(newComponent, component)
        newComponent.id = newId
        newComponentList.push(newComponent)
        this.setState({
            componentList: newComponentList,
            selectedComponent: newComponent,
            doubleClickComponent: undefined,
            id: newId
        })
    }

    ////////////   TableColumnPopup Callbacks   ////////////////
    onTableColumnPopupClose = () => {
        this.setState({
            isTableColumnPopupRequired: false,
            tableColumnType: undefined
        })
    };

    onTableColumnPopupSave = (data, tableType) => {
        if(tableType === undefined) {
            let dataToUpdate = this.state.documentData;
            dataToUpdate["tableColumnPopup"] = data;
            TemplateSettingsManager.updateTablePopupInfo(data)
            this.setState({
                tableColumnData: data,
                isTableColumnPopupRequired: false,
                tableColumnType: undefined
            }, () => {
                this.onDataChanged(dataToUpdate)
            })
        }
        else {
            let dataToUpdate = this.state.documentData;
            TemplateSettingsManager.updateAccountProductTable(data)
            this.setState({
                isTableColumnPopupRequired: false,
                tableColumnType: undefined
            }, () => {
                this.onDataChanged(dataToUpdate)
            })
        }
    };

    ///////////////////////////////////////////////////////////////
    ///////////////  Methods to invoke API Calls  /////////////////
    ///////////////////////////////////////////////////////////////

    //Document Related
    getDocumentDetails(result, isShareMode, isSharingMode, printInfo) {
        if (isShareMode) {
            showAlert(AlertType.user_input, getLocalisedText('document_enter_password_title'), getLocalisedText('document_enter_password_message'), [{
                title: getLocalisedText('proceed'), className: "bg-blue text-white", onClick: (passwordReceived) => {
                    this.fetchDocumentDetails(result, passwordReceived)
                }
            }])
        }
        else if (isSharingMode) {
            this.fetchDocumentDetails(result, null)
        }
        else if (printInfo !== undefined) {
            TemplateSettingsManager.setPrintInfo(printInfo)
            this.fetchPrintInfoDetails(printInfo)
        } else {
            ApiManager.getDocumentDetails(result, this)
        }
    }

    fetchDocumentDetails(result, passwordReceived) {
        let id = result.split('?')[0]
        let shareLinkRepo = new ShareLinkRepository()
        shareLinkRepo.getPublicDocumentDetails(id, passwordReceived, (dataFromAPI) => {
            let payload_decoded = Utility.decodeStringForTemplate(dataFromAPI.payload)
            this.templateSettingsResponse(payload_decoded.settings, payload_decoded, true, true)
            NewDataParser.updateMissingTableColumn(payload_decoded)
            this.updateDimensions()
        }, (error) => {
            if (error.status === 404 && error.message !== null) {
                showAlert(AlertType.standard, getLocalisedText('document_not_found_title'), error.message, [])
            } if (error.status === 403 && error.message !== null) {
                showAlert(AlertType.user_input, getLocalisedText('document_incorrect_password_title'), getLocalisedText('document_incorrect_password_message'), [{
                    title: getLocalisedText('proceed'), className: "bg-blue text-white", onClick: (passwordReceived) => {
                        this.fetchDocumentDetails(result, passwordReceived)
                    }
                }])
            } else {
                showAlert(AlertType.standard, getLocalisedText('document_expired_title'), getLocalisedText('document_expired_message'), [])
            }
        })
    }

    fetchPrintInfoDetails(printInfo) {
        let printInfoRepo = new PrintInfoRepository()
        printInfoRepo.getPrintInfoDetails(printInfo, (response) => {
            if(response.pageFormat !== undefined && response.pageFormat !== null) {
                if(response.pageFormat !== '') {
                    TemplateSettingsManager.setPageFormat(response.pageFormat)
                }
            }
            var payload = TemplateSettingsManager.getIsMultipleDocument() ? response.printInformations : response.printInformation.payload
            this.templateSettingsResponse(JSON.parse(response.templateData), payload, true, false)
        }, (error) => {
            if (error.status === 404 && error.message !== null) {
                showAlert(AlertType.standard, getLocalisedText('document_not_found_title'), error.message, [])
            } else {
                showAlert(AlertType.standard, getLocalisedText('document_expired_title'), getLocalisedText('document_expired_message'), [])
            }
        })
    }

    //Template Settings Related
    saveTemplateSettings(imagePath, templateName) {
        var name = 'Default_template_settings'
        if(templateName !== undefined) {
            if(templateName.length > 0) {
                name = templateName
            }
        }
        if (TemplateSettingsManager.getRemoteTemplateUID()) {
            TemplateSettingsManager.updateMissingInnerWidthForComponentList()
            this.templateRepo.updateTemplateSettings(TemplateSettingsManager.getTemplateNameType(this.state.documentData.type), TemplateSettingsManager.getRemoteTemplateUID(), name, imagePath,
                (successResponse) => {
                    if (
                      this.state.documentData.type !== undefined &&
                      this.state.documentData.type.toLowerCase() === "payslip"
                    ) {
                        let envDomain = ApiConstants.URL.COOKIE_DOMAIN;
                        let isCookiePresent = this.checkIfCookieIsPresentAndUpdate(envDomain);
                        if(!isCookiePresent) {
                            const cookies = new Cookies();
                            cookies.set(
                              "documentUpdateCount",
                              this.state.documentUpdateCount,
                              { path: "/", domain: envDomain }
                            );
                        }
                    }
                    this.didSuccessfullySaveTemplate(true)
                }, (failureResponse) => {
                    showToast(getLocalisedText('template_update_failure_message'), TOAST_TYPE.FAILURE)
                })
        } else {
            this.templateRepo.saveTemplateSettings(TemplateSettingsManager.getTemplateNameType(this.state.documentData.type), name, imagePath,
                (successResponse) => {
                    const type = this.documentType ?? this.state.documentData.type
                    ApiManager.getTemplateSettingsFromRemote(TemplateSettingsManager.getTemplateNameType(type.toLowerCase()), this, this.fromERPData);
                    if (
                      this.state.documentData.type !== undefined &&
                      this.state.documentData.type.toLowerCase() === "payslip"
                    ) {
                      let envDomain = ApiConstants.URL.COOKIE_DOMAIN;
                      let isCookiePresent =
                        this.checkIfCookieIsPresentAndUpdate(envDomain);
                      if (!isCookiePresent) {
                        const cookies = new Cookies();
                        cookies.set(
                          "documentUpdateCount",
                          this.state.documentUpdateCount,
                          { path: "/", domain: envDomain }
                        );
                      }
                    }
                    this.didSuccessfullySaveTemplate(false)
                }, (failureResponse) => {
                    showToast(getLocalisedText('template_save_failure_message'), TOAST_TYPE.FAILURE)
                })
        }
    }

    ///////////////////////////////////////////////////////////////////////
    // Check if cookie is present and update
    ///////////////////////////////////////////////////////////////////////

    checkIfCookieIsPresentAndUpdate (domain) {
        let cookie = new Cookies()
        let isCookiePresent = false;
        let initialValue = cookie.get("documentUpdateCount");
        if (initialValue !== "" && initialValue !== undefined) {
            cookie.set("documentUpdateCount", this.state.documentUpdateCount, { path: '/', domain: domain });
            isCookiePresent = true;
            this.setState({
                documentUpdateCount: this.state.documentUpdateCount + 1
            })
        }
        /**
         * Commenting can be used further
         */
        // let windowCookie = `; ${document.cookie}`;
        // if(windowCookie != "") {
        //     const parts = windowCookie.split(`; documentUpdateCount=`);
        //     if(parts.length === 2) {
        //         let value = parts.pop().split(";").shift();
        //         if(typeof value === "string" && value !== "") {
        //             let documentValue = parseInt(value);
        //             documentValue = this.state.documentUpdateCount++;
        //             this.state.documentUpdateCount = documentValue;
        //             let initailString = `documentUpdateCount=${value}`;
        //             let updatedString = `documentUpdateCount=${documentValue}`;
        //             document.cookie = document.cookie.replace(initailString,updatedString);
        //             isCookiePresent = true;
        //         }
        //     }
        // }
        return isCookiePresent;
    }

    ///////////////////////////////////////////////////////////////////////
    // Check User Logged in Related Call back Methods
    ///////////////////////////////////////////////////////////////////////
    loginStatusApiResponseReceived() {
        this.setState({
            userLoggedIn: AppManager.isUserLoggedIn()
        })
        ApiManager.getCustomFields(this)
        this.getParamVariables()
    }

    ///////////////////////////////////////////////////////////////////////
    // Document API Related Call back Methods
    ///////////////////////////////////////////////////////////////////////
    documentResponseSuccess(dataFromAPI) {
        if(dataFromAPI.payload.type.toLowerCase()===""){
            dataFromAPI.payload.type = "machine"
        }
        if (dataFromAPI !== undefined && dataFromAPI !== null) {
            this.fromERPData = dataFromAPI.payload
            this.documentCode = dataFromAPI.documentCode
            
            this.documentType = dataFromAPI.payload.type
            if (dataFromAPI.documentType !== undefined && dataFromAPI.documentType !== null) {
                if(getIsPayslip(dataFromAPI.documentType.toLowerCase())) {
                    this.documentType = dataFromAPI.documentType.toLowerCase()
                }
                if (getIsSalesReturn(dataFromAPI.documentType)) {
                    this.documentType = dataFromAPI.documentType.toLowerCase()
                    dataFromAPI.payload.type = dataFromAPI.documentType.toLowerCase()
                    this.fromERPData = dataFromAPI.payload
                }
                if (getIsBOMModule(dataFromAPI.documentType)) {
                    this.documentType = dataFromAPI.documentType.toLowerCase()
                    dataFromAPI.payload.type = dataFromAPI.documentType.toLowerCase()
                    this.fromERPData = dataFromAPI.payload
                }
                if(dataFromAPI.documentType === 'MACHINE'){
                    this.documentType = 'machine'
                    dataFromAPI.payload.type = "machine".toLowerCase()
                    this.fromERPData = dataFromAPI.payload
                }
            }
        }

        ApiManager.getTemplateSettingsFromRemote(TemplateSettingsManager.getTemplateNameType(this.documentType.toLowerCase()), this, this.fromERPData)
    }

    documentResponseFailure() {
        // alert("Server error occurred. Please try again later.")
        // window.history.back();
    }

    ///////////////////////////////////////////////////////////////////////
    // Template Settings API Related Call back Methods
    ///////////////////////////////////////////////////////////////////////
    didSuccessfullySaveTemplate(isUpdated) {
        this.setState({
            selectedComponent: undefined,
            doubleClickComponent: undefined,
            isSavingTemplate: false,
        }, () => {
            this.showSaveTemplatePopup()
        })
        // this.setState({ isSavingTemplate: false }, () => {
        //     TemplateSettingsManager.revertTemplateSettingManagerToFactorySettings()
        //     TemplateSettingsManager.removeAllLocalKeys()
        //     let alertMessage = getLocalisedText('template_save_success_message')
        //     if (isUpdated) {
        //         alertMessage = getLocalisedText('template_update_success_message')
        //     }
        //     showToast(alertMessage, TOAST_TYPE.SUCCESS)
        // })
    }

    templateSettingsResponse(settingsData, dataFromAPI, isSharing = false, isComputedData = false) {
        if (settingsData) {
            let templateDataFromRemote
            if (isSharing === true) {
                templateDataFromRemote = settingsData
                if (this.state.printInfo !== undefined && settingsData !== undefined) {
                    if(this.state.printInfo.tId !== undefined) {
                        TemplateSettingsManager.updateRemoteTemplateUID(this.state.printInfo.tId)
                    }
                }
            } else {
                TemplateSettingsManager.updateRemoteTemplateUID(settingsData.id)
                TemplateSettingsManager.setPageFormat(settingsData.pageFormat)
                TemplateSettingsManager.setCustomTemplateName(settingsData.templateName)
                templateDataFromRemote = JSON.parse(settingsData.templateData ?? settingsData)

                let templateID = this.state.templateIdFromParam === null || this.state.templateIdFromParam === undefined ? templateDataFromRemote.templateInfo.id :this.state.templateIdFromParam
                let themeColor = this.state.templateIdFromParam === null || this.state.templateIdFromParam === undefined ? templateDataFromRemote.templateInfo.themeColor : DocumentManager.getThemeColor(undefined, this.state.templateIdFromParam)
                templateDataFromRemote.templateInfo.id = templateID
                templateDataFromRemote.templateInfo.themeColor = themeColor

                //Update missing JSON Keys
                if (templateDataFromRemote.documentInfo.type === undefined) {
                    templateDataFromRemote.documentInfo['type'] = { 'isVisible': true }
                }
                if (templateDataFromRemote.documentInfo.documentDetails.stockTable === undefined) {
                    templateDataFromRemote.documentInfo.documentDetails['stockTable'] = { 'isVisible': true }
                }
                if (templateDataFromRemote.documentInfo.documentDetails.paymentTable === undefined) {
                    templateDataFromRemote.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
                }
                this.settingsData = templateDataFromRemote

                var decimal = JSON.parse(settingsData.templateData).documentInfo.documentDetails.decimalScale

                if (decimal === undefined || decimal === null) {
                    var defaultDecimal = this.fromERPData.decimalScale ? this.fromERPData.decimalScale : 2
                    TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['decimalScale'] = { 'value': defaultDecimal }
                    AppManager.setDecimalScale(defaultDecimal)
                }
            }
            let a = this.updateLocalStorageUpdated(templateDataFromRemote)
            const that = this;

            if(dataFromAPI !== null) {
                a.then(function () {
                    that.modifyDataToShow(dataFromAPI, isComputedData)
                }, function (err) {
                    console.log(err)
                });
            }
            else {
                this.assignTemplateToDefaultDocument(settingsData)
            }
        } else {
            if (dataFromAPI !== null) {
                let type = dataFromAPI?.type ?? ''
                if(Array.isArray(dataFromAPI)) {
                    if(dataFromAPI.length > 0) {
                        type = dataFromAPI[0].payload.type ?? ''
                    }
                }
                const tableColumns = TemplateSettingsManager.getDefaultTableColumnByType(type)
                TemplateSettingsManager.defaultTemplateSettings.tableInfo = tableColumns

                this.modifyDataToShow(dataFromAPI, isComputedData)
            }
        }
    }

    assignTemplateToDefaultDocument(settingsData) {
        if (settingsData && settingsData !== null) {
            const type = this.state.documentData ? this.state.documentData.type : 'invoice'
            var template = JSON.parse(settingsData.templateData)
            TemplateSettingsManager.defaultTemplateSettings = template
            TemplateSettingsManager.updateTemplateID(template.templateInfo.id)
            let documentData = DocumentManager.updateShowHideSetting(this.state.documentData);
            documentData = NewDataParser.getDocumentDataFromTemplate(documentData)
            this.setState({
                templateId: template.templateInfo.id,
                documentData: {
                    ...documentData,
                    templateId: template.templateInfo.id,
                    themeColor: template.templateInfo.themeColor,
                    tableColumnPopup: template.tableInfo
                },
                tableColumnData: template.tableInfo
            }, () => {
                // Add document type specific table info
                this.updateTableInfo(type);
            })
        }
        else {
            TemplateSettingsManager.initializeDefaultTemplate()
            const type = this.state.documentData ? this.state.documentData.type : 'invoice'
            TemplateSettingsManager.resetShowHideSection(type, this.state.documentData)
            let documentData = DocumentManager.updateShowHideSetting(this.state.documentData);
            documentData.country = ApiConstants.TENANT_COUNTRY
            const tableColumns = TemplateSettingsManager.getDefaultTableColumnByType(type)
            TemplateSettingsManager.defaultTemplateSettings.tableInfo = tableColumns
            this.setState({
                documentData: {
                    ...documentData,
                    tableColumnPopup: tableColumns,
                },
                tableColumnData: tableColumns,
            }, () => {
                this.setState({
                    templateId: parseInt(1),
                    documentData: {
                        ...documentData,
                        templateId: 1,
                        themeColor: undefined
                    },
                })

                // Add document type specific table info
                this.updateTableInfo(type);
            })
        }
    }

    templateSettingsListCallback(templateList, templateData) { 
        if (templateData !== undefined && templateData !== null) {
            TemplateSettingsManager.updateRemoteTemplateUID(templateData.id)
            TemplateSettingsManager.setPageFormat(templateData.pageFormat)
            TemplateSettingsManager.setCustomTemplateName(templateData.templateName)
            this.settingsData = templateData
            this.assignTemplateToDefaultDocument(templateData)
        }
        else {
            TemplateSettingsManager.updateRemoteTemplateUID(undefined)
            TemplateSettingsManager.setPageFormat('a4')
            TemplateSettingsManager.setCustomTemplateName('')
            this.settingsData = null
            this.assignTemplateToDefaultDocument(templateData)
        }
    }


    ///////////////////////////////////////////////////////////////
    /////////////////////  Helper  methods  ///////////////////////
    ///////////////////////////////////////////////////////////////

    // Method to update local storage
    async updateLocalStorageUpdated(templateDataFromRemote) {
        return new Promise(function (resolve) {
            TemplateSettingsManager.updateTemplateSettings(templateDataFromRemote)
            resolve()
        })
    }

    // Method to Parse ERP Data into DocBuilder Data
    modifyDataToShow(dataFromAPI, isComputedData) {
        let cartonCount = Utility.getCartonCount(dataFromAPI)
        if (cartonCount > 1) {
            let id = this.state?.printInfo?.id ?? undefined  
            if(id) {
                let newId = ''
                let newPrintInfo = this.state.printInfo
                for (let index = 0; index < cartonCount; index++) {
                    newId = newId + id + ','
                }
                newPrintInfo.id = newId
                newPrintInfo.isMultiCarton = true
                this.setState({ printInfo: newPrintInfo })
                TemplateSettingsManager.setPrintInfo(newPrintInfo)
            }
        }
        if(TemplateSettingsManager.getIsMultipleDocument()) {
            var newDocumentDataList = []
            var templateId = 1
            const isMultiCarton = this.state.printInfo.isMultiCarton ?? false
            if(dataFromAPI.length > 0) {
                dataFromAPI.forEach((element, index) => {
                    const { templateID, templateToShow } = this.setPrintData(element.payload, isComputedData)
                    templateToShow.documentIndex = index
                    newDocumentDataList.push(templateToShow)
                    templateId = templateID
                });
                this.setData(templateId, newDocumentDataList[0].type, newDocumentDataList[0], newDocumentDataList)
                MultiPageUtility.renderForMultiDocumentPrint(newDocumentDataList, this.state.printInfo)
            }
            if(isMultiCarton) {
                for (let index = 0; index < cartonCount; index++) {
                    const { templateID, templateToShow } = this.setPrintData(dataFromAPI, isComputedData, index)
                    templateToShow.documentIndex = index
                    newDocumentDataList.push(templateToShow)
                    templateId = templateID
                }
                this.setData(templateId, newDocumentDataList[0].type, newDocumentDataList[0], newDocumentDataList)
                MultiPageUtility.renderForMultiDocumentPrint(newDocumentDataList, this.state.printInfo)
            }
        }
        else {
            const { templateID, templateToShow} = this.setPrintData(dataFromAPI, isComputedData)
            this.setData(templateID, templateToShow.type, templateToShow)
            if(Utility.getIsRepeatableHeaderFooterTemplate(templateID)) {
                NewMultiPageUtility.renderForPrintWithRepeatableHeaderFooter(templateToShow, this.state.printInfo)
            } else {
                MultiPageUtility.renderForPrint(templateToShow, this.state.printInfo)
            }
        }
    }

    setPrintData(data, isComputedData, index) {
        let templateToShow = NewDataParser.getTemplateDataFromERP(data, this.state.isReadOnlyMode, isComputedData, index)
        templateToShow['isReadOnlyMode'] = this.state.isReadOnlyMode
        this.setState({ isFromERP: true, tableColumnData: TemplateSettingsManager.defaultTemplateSettings.tableInfo })
        var templateID = this.state.templateIdFromParam === null || this.state.templateIdFromParam === undefined ? templateToShow.templateId : this.state.templateIdFromParam

        if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
            if (Utility.getIsIndiaCountry(templateToShow)) {
                if (getIsQuotation(templateToShow.type) ||
                    getIsSalesOrder(templateToShow.type) ||
                    getIsInvoice(templateToShow.type) ||
                    getIsRequestForQuotation(templateToShow.type) ||
                    getIsPurchaseInwardQuotation(templateToShow.type) ||
                    getIsBill(templateToShow.type) ||
                    getIsOrder(templateToShow.type)) {
                    templateID = 36
                    templateToShow.templateId = 36
                    TemplateSettingsManager.updateTemplateID(36)
                }
            } else if (getIsPayslip(templateToShow.type)) {
                var id = TemplateManager.getPeopleInitTemplate(templateToShow.country)
                templateID = id
                templateToShow.templateId = id
                TemplateSettingsManager.updateTemplateID(id)
            }

            if (Utility.getIsReceiptPaperSize()) {
                templateID = 1
                templateToShow.templateId = 1
                TemplateSettingsManager.updateTemplateID(1)
                templateToShow.themeColor = DocumentManager.getReceiptThemeColor(1)
                TemplateSettingsManager.resetDefaultDataForReceipt(templateToShow)
            }
        }

        return {templateID, templateToShow}
    }

    // Method to respond to Table Click
    tableClicked = (tableType) => {
        this.setState({ isTableColumnPopupRequired: true, tableColumnType: tableType })
    }

    stockTableClicked = () => {
        var data = this.state.documentData;
        data["showStockTable"] = false;
        TemplateSettingsManager.updateStockTableVisibility(false)
        this.onDataChanged(data)
    }

    paymentTableClicked = () => {
        var data = this.state.documentData;
        data["showPaymentTable"] = false;
        TemplateSettingsManager.updatePaymentTableVisibility(false)
        this.onDataChanged(data)
    }

    qcInspectionTableClicked = (tableType) => {
        this.setState({ isQCInspectionTableColumnPopupRequired: true, tableColumnType: tableType })
    }

    // Methods for further actions on header menu
    checkIfUserLoggedInBeforeExport(type) {
        if (!this.state.bypassUserLoginCheck) {
            if (!AppManager.isUserLoggedIn()) {
                this.showSignInPopup()
                return
            }
        }

        if (type === ExportType.PRINT) {
            this.setState({ isPrintingPDF: true }, () => {
                PdfUtility.generatePrint(this)
            })
        } else if (type === ExportType.DOWNLOAD_PDF) {
            this.setState({ isDownloadingPDF: true }, () => {
                PdfUtility.generatePDF(this.state.documentData.type, this.state.documentData.refNumber, this)
            })
        } else if (type === ExportType.EMAIL) {
            this.setState({ generatingFile: true })
            PdfUtility.getPDFPath(this.state.documentData.refNumber).then(response => {
                this.setState({
                    sharableURL: Utility.getSharableURL(this.state.documentData),
                    showMailComposer: true,
                    pdfURL: response,
                    generatingFile: false,
                    isEmailPDF: false
                })
            })
        } else if (type === ExportType.VIEW_PDF) {
            this.setState({ isViewPdf: true }, () => {
                PdfUtility.generatePDF(this.state.documentData.type, this.state.documentData.refNumber, this, ExportType.VIEW_PDF)
            })
        }
        else if (type === ExportType.PREVIEW_PDF) {
            this.setState({ isPreviewingPdf: true }, () => {
                PdfUtility.generatePDF(this.state.documentData.type, this.state.documentData.refNumber, this, ExportType.PREVIEW_PDF)
            })
        }
    }

    downloadingComplete() {
        this.setState({ isDownloadingPDF: false, bypassUserLoginCheck: false })
    }

    printingComplete() {
        this.setState({ isPrintingPDF: false, bypassUserLoginCheck: false })
    }

    previewPdfComplete() {
        this.setState({ isPreviewingPdf: false})
    }

    resetDefaultData(data) {
        var templateId = this.state.templateId
        if (this.state.templateType !== undefined) {
            if (data.type !== this.state.templateType) {
                TemplateSettingsManager.resetDefaultData(data, this.state.templateType)
                DocumentManager.setCurrentData(null)
                if (this.state.isFromERP) {
                    data.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo
                    this.setData(this.state.templateId, data.type, data)
                }
                else {
                    templateId = this.state.templateId
                    if (getIsPayslip(this.state.templateType) || getIsPayslip(data.type)) {
                        templateId = TemplateManager.getInitTemplate(data.type.toLowerCase())
                    }
                    var newData = DocumentManager.getDefaultData(templateId, data.type)
                    newData.tableColumnPopup = TemplateSettingsManager.defaultTemplateSettings.tableInfo

                    this.setData(templateId, data.type, newData)
                    if (this.state.userLoggedIn && AppManager.getDocumentId() === undefined) {
                        TemplateSettingsManager.setCustomTemplateList([])
                        ApiManager.getTemplateSettings(TemplateSettingsManager.getTemplateNameType(data.type.toLowerCase()), this)
                    }
                }
            }
            else {
                if (data.templateId !== this.state.templateId && Utility.getIsReceiptPaperSize()) {
                    templateId = data.templateId
                }

                this.setState({ documentData: data, templateType: data.type, templateId: templateId })
            }
        }
    }

    // Save Template Popup related methods
    showSaveTemplatePopup() {
        if (!AppManager.isUserLoggedIn()) {
            this.showSignInPopup()
            return
        }
        this.setState({ isAfterSaveTemplatePopupRequired: true })
    }

    onSaveTemplatePopupClose = () => {
        this.setState({
            isAfterSaveTemplatePopupRequired: false,
            isSaveTemplatePopupRequired: false,
            isSavingTemplate: false,
            isTappedOutside: true,
            isHeaderFooterPopupRequired: false,
            isAdditionalTablePopupRequired: false,
            isWatermarkRequiredPopupRequired: false,
            isTableColumnAlignmentPopupRequired: false,
            isTableColumnVerticalAlignmentPopupRequired: false,
            isBOMComponentTablePopupRequired: false,
            isDocumentLabelPopupRequired: false,
            isPageCountPopupRequired: false,
            isApprovalSectionPopupRequired: false,
            isCustomApprovalSectionPopupRequired: false,
            isQCInspectionTableColumnPopupRequired: false,
            isDraftWatermarkPopupRequired: false,
            isPaperMarginRequired: false
        })
    }

    onPayslipConfigurationClosed = () => {
        this.setState({
            isPayslipFieldPopupRequired: false,
            isPayslipTablePopupRequired: false,
        })
    }

    onPayslipEarningsConfigurationClosed = () => {
        this.setState({
            isPayslipEarningsPopupRequired: false
        })
    }

    onEmptyCustomFieldClose = () => {
        this.setState({
            isEmptyCustomFieldPopupRequired: false
        })
    }

    onDeleteTemplate = () => {
        if(this.state.deleteTemplateItem !== undefined) {
            this.templateRepo.deleteTemplateSettings(this.state.deleteTemplateItem.id,
                (successResponse) => {
                    showToast(getLocalisedText('delete_template_success_message'), TOAST_TYPE.SUCCESS)
                    ApiManager.getTemplateSettingsFromRemote(TemplateSettingsManager.getTemplateNameType(this.documentType.toLowerCase()), this, this.fromERPData)
                    this.resetDeleteTemplateItem()
                }, (failureResponse) => {
                    showToast(getLocalisedText('delete_template_failure_message'), TOAST_TYPE.FAILURE)
                    this.resetDeleteTemplateItem()
                })
        }
    }

    resetDeleteTemplateItem = () => {
        this.setState({
            isDeleteTemplateRequired: false,
            deleteTemplateItem: undefined
        })
    }

    updateTableInfo = (type) => {
        if (getIsInvoice(type) || getIsSalesOrder(type)) {
            if (!this.state.tableColumnData.map(x => x.type).includes(TableColumnPopupType.cogs)) {
                const cogs = { "type": TableColumnPopupType.cogs, "isSelected": false, "name": "cogs" }

                const updateTableColumnData = [...this.state.tableColumnData]
                const totalIndex = updateTableColumnData.map(x => x.type).indexOf(TableColumnPopupType.totalAmount)
                if (totalIndex !== -1) {
                    updateTableColumnData.splice(totalIndex + 1, 0, cogs)
                } else {
                    updateTableColumnData.splice(30, 0, cogs)
                }
                
                this.setState({
                    tableColumnData: updateTableColumnData
                })
            }
        } else {
            if (this.state.tableColumnData.map(x => x.type).includes(TableColumnPopupType.cogs)) {
                const updateTableColumnData = [...this.state.tableColumnData]
                const index = updateTableColumnData.map(x => x.type).indexOf(TableColumnPopupType.cogs);
                updateTableColumnData.splice(index, 1);

                this.setState({
                    tableColumnData: updateTableColumnData
                })
            }
        }
    }

    renderChequeDetailsPopup() {
        return (
            <div className="RowDiv" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute', zIndex: 9999 }}>
                <ChequeDetailsPopup
                    onCancelClicked={() => {
                        this.setState({
                            isChequePopupRequired: false
                        })
                    }}
                    onSaveClicked={(data) => {
                        this.setState({
                            isChequePopupRequired: false,
                            documentData: data
                        })
                    }}
                    onResetItem={(data) => {
                        this.setState({
                            documentData: data
                        });
                    }}
                    data={this.state.documentData}
                />
            </div>
        );
    }
}
