
import { getLocalisedText } from "../../../Translate/LanguageManager"
import Utility, { getVW } from "../../../Utilities/Utility"
import { ColumnStyle } from "../ColumnStyle"
import { FooterRow } from "../FooterRow"
import { HeaderColumn } from "../HeaderColumn"
import { ItemColumn } from "../ItemColumn"
import { TableOption } from "../TableOption"
import { WorkOrderTableItem } from "./WorkOrderTableItem"
import { WorkOrderTableWidth } from "./WorkOrderTableWidth"
import NumberUtility from "../../../Utilities/NumberUtility"
import { FONT_SIZE, PRINT_SCALE, TableDimensionType, TableColumnPopupType, CONSTANTS } from "../../../Constants/Constants"
import { TableHeader } from "../TableHeader"
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager"
import { getSortedHeader, getSortedLineItems, getTablePadding } from "../TableUtility"
import { ProductTableGroupHeaderColumn } from "../product/ProductTableGroupHeaderColumn"
import TableColumnAlignmentParser from "../../../Helper/TableColumnAlignmentParser"

export class WorkOrderTable {
    static footerColumnList: HeaderColumn[]
    static groupHeaderIndexList: number[]
    static getTableOption(canUserEdit: boolean) {
        var tableOption = new TableOption()
        tableOption.isShowBorder = true
        tableOption.isShowFooter = false
        tableOption.isShowSortButton = canUserEdit
        return tableOption
    }

    static getDefaultHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newHeaderColumn = new HeaderColumn()
        newHeaderColumn.type = 'string'
        newHeaderColumn.style = new ColumnStyle()
        newHeaderColumn.style.backgroundColor = tableStyle.headerBackgroundColor
        newHeaderColumn.style.borderColor = tableStyle.headerBorderColor
        newHeaderColumn.style.fontColor = tableStyle.headerTextColor
        newHeaderColumn.style.borderStyle = 'solid'
        newHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        if (tableStyle.headerBorderBottomColor !== undefined) {
            newHeaderColumn.style.borderBottomColor = tableStyle.headerBorderBottomColor
            newHeaderColumn.style.borderBottomWidth = tableStyle.headerBorderBottomWidth
        }
        newHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.fontSize = FONT_SIZE.regular
        newHeaderColumn.style.fontWeight = 'bold'
        return newHeaderColumn
    }
    static getHeaderColumnList(columnOption: any, tableStyle: any, rawData: any) {
        var headerColumnList: HeaderColumn[] = []
        if (columnOption === undefined || columnOption === null) {
            return
        }
        if (columnOption.length === 0) {
            return
        }

        var sortedHeader = getSortedHeader()
        var defaultTableColumnAlignmentList = TableColumnAlignmentParser.getDefaultAlignmentForFulfilment()
        var tableColumnAlignmentList = TableColumnAlignmentParser.getTableColumnAlignment()
        var tableColumnVerticalAlignmentList = TableColumnAlignmentParser.getTableColumnVerticalAlignment()
        if (sortedHeader.length > 0) {
            sortedHeader.forEach(element => {
                var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, rawData.printScale)
                newHeaderColumn = this.updateHeaderColumn(newHeaderColumn, new TableHeader(element), defaultTableColumnAlignmentList, tableColumnAlignmentList, tableColumnVerticalAlignmentList)
                headerColumnList.push(newHeaderColumn)
            });
        }

        var width = columnOption[0].width

        if (width === undefined || width === null || this.getIsResetRequiredForColumnWidth(columnOption)) {
            var newColumnOption = columnOption
            var WorkOrderTableWidth = this.getHeaderColumnWidth(headerColumnList)
            headerColumnList.forEach(element => {
                element.style.width = this.getHeaderItemColumnWidth(element.name, WorkOrderTableWidth, element.code) + '%'
            });

            newColumnOption.forEach((element: TableHeader) => {
                element.width = this.getHeaderItemColumnWidth(element.type, WorkOrderTableWidth, element.code) + '%'
            });

            TemplateSettingsManager.updateTablePopupInfo(newColumnOption)
        }
        else {
            headerColumnList.forEach(element => {
                var items = columnOption.filter((x: TableHeader) => x.type === element.name)
                if (items !== undefined && items !== null) {
                    if (items.length > 0) {
                        var width = items[0].width
                        if (width !== undefined) {
                            element.style.width = width
                        }
                    }
                }
            });
        }
        // headerColumnList = setLastColumnHeaderRightAlign(headerColumnList, [])
        return headerColumnList
    }

    static getIsResetRequiredForColumnWidth(columnOptions: any) {
        var isResetRequired = false
        var dataColumn = columnOptions
        var selectedColumnWidth = dataColumn.filter((x: any) => x.isSelected)
        selectedColumnWidth.forEach((element: any) => {
            if (element.width === null || element.width === undefined) {
                isResetRequired = true
            }
            else if (element.width === '0%') {
                isResetRequired = true
            }
        });

        var isNotSelectedColumn = dataColumn.filter((x: any) => !x.isSelected)

        isNotSelectedColumn.forEach((element: any) => {
            if (element.width !== null && element.width !== undefined) {
                var num = Number(element.width.replace('%', ''))
                if (num > 0) {
                    isResetRequired = true
                }
            }
        });

        return isResetRequired
    }
    static updateHeaderColumn(headerColumn: HeaderColumn, tableHeader: TableHeader, defaultTableColumnAlignmentList: any,tableColumnAlignmentList: any,tableColumnVerticalAlignmentList: any) {
        headerColumn.columnOption.isShowColumn = tableHeader.isSelected
        headerColumn.name = tableHeader.type
        headerColumn.label = getLocalisedText(tableHeader.name)
        headerColumn.code = tableHeader.code
        headerColumn.style.alignment = 'center'

        var columnAlignment = defaultTableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
        if (columnAlignment !== undefined) {
            headerColumn.style.alignment = columnAlignment.alignment
        }

        if (TableColumnPopupType.productCustomField === tableHeader.type) {
            if (tableHeader.code === undefined) {
                headerColumn.style.alignment = 'left'
            }
        }

        if (tableColumnAlignmentList !== undefined && tableColumnAlignmentList !== null) {
            if (tableColumnAlignmentList.length > 0) {
                var savedColumnAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnAlignment !== undefined) {
                    headerColumn.style.alignment = savedColumnAlignment.alignment
                }
            }
        }

        if (tableColumnVerticalAlignmentList !== undefined && tableColumnVerticalAlignmentList !== null) {
            if (tableColumnVerticalAlignmentList.length > 0) {
                var savedColumnVerticalAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnVerticalAlignment !== undefined) {
                    headerColumn.style.verticalAlignment = savedColumnVerticalAlignment.alignment
                }
            }
        }

        return headerColumn
    }

    static getHeaderColumnWidth(headerColumnList: HeaderColumn[]) {
        var showWidthConfig = new WorkOrderTableWidth()
        showWidthConfig.setIsShowWidth(headerColumnList)
        showWidthConfig.getTableHeaderWidths()
        return showWidthConfig
    }

    static getHeaderItemColumnWidth(type: string, workOrderTableWidth: WorkOrderTableWidth, code?: string) {
        var width = 0
        switch (type) {
            case TableColumnPopupType.code:
                width = workOrderTableWidth.code
                break;
            case TableColumnPopupType.productName:
                width = workOrderTableWidth.name
                break;
            case TableColumnPopupType.description:
                width = workOrderTableWidth.description
                break;
            case TableColumnPopupType.image:
                width = workOrderTableWidth.image;
                break;
            case TableColumnPopupType.quantity:
                width = workOrderTableWidth.quantity;
                break;
            case TableColumnPopupType.lineNumber:
                width = workOrderTableWidth.lineNumber;
                break;
            case TableColumnPopupType.serialBatch:
                width = workOrderTableWidth.serialBatch;
                break;
            case TableColumnPopupType.orderPrepCompleted: 
                width = workOrderTableWidth.orderPrepCompleted
                break;
            case TableColumnPopupType.productCustomField:
                width = workOrderTableWidth.productCustomField
                if (code !== undefined) {
                    var itemWidth = workOrderTableWidth.getProductCustomFieldWidthByCode(code)
                    if (itemWidth !== undefined) {
                        width = itemWidth
                    }
                }
                break;
            default:
                break;
        }

        return width
    }

    static getIsShowColumn(headerColumnList: HeaderColumn[], name: string) {
        var filterValue = headerColumnList.filter(x => x.name === name)
        if (filterValue === undefined || filterValue === null) {
            return false
        }
        if (filterValue.length === 0) {
            return false
        }
        return filterValue[0].columnOption.isShowColumn
    }
    static getData(documentData: any) {
        var lineItems: string[][] = []
        var dummyData = [
            ['F-001', getLocalisedText('item_name'), getLocalisedText("item_description"), 'N/A', '1', '1', ''],
            ['F-002', getLocalisedText('item_name'), getLocalisedText("item_description"), 'N/A', '1', '1', ''],
            ['F-003', getLocalisedText('item_name'), getLocalisedText("item_description"), 'N/A', '1', '1', ''],
        ]
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            var currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return []
            }
            var sortedItems = getSortedLineItems(documentData.lineItems)

            //processing grouping for the for product attribute
            var groupItems = this.getGroupData(sortedItems, documentData, currency)

            groupItems.forEach((element: any) => {
                lineItems.push(element)
            });

            return lineItems
        }
    }

    static getGroupData(items: any, documentData: any, currency: string) {
        var lineItems: string[][] = []
        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        var isMultiProductTable = TemplateSettingsManager.getIsMultiProductTable()
        var grouping = 'none'
        if (newProductGrouping !== undefined && newProductGrouping !== null) {
            if (newProductGrouping.selection !== undefined) {
                grouping = newProductGrouping.selection
            }
        }
        if (grouping === 'none') {
            items.forEach((element: any) => {
                lineItems.push(new WorkOrderTableItem(element, documentData, currency).toString())
            });
            return lineItems
        }
        var attributes: string[] = []
        this.groupHeaderIndexList = []
        items.forEach((element: any) => {
            var label = ''
            if (element.customFields !== undefined && element.customFields !== null) {
                label = this.getAttributeString(element.customFields)
            }
            if (!attributes.includes(label)) {
                attributes.push(label)
            }
        });

        if (grouping === CONSTANTS.PRODUCT_GROUP && !isMultiProductTable) {
            attributes = []
            items.forEach((element: any) => {
                if (element.productGroupName !== undefined && element.productGroupName !== null) {
                    if (Array.isArray(element.productGroupName)) {
                        if (element.productGroupName.length > 0) {
                            element.productGroupName.forEach((groupName: string) => {
                                if (!attributes.includes(groupName)) {
                                    attributes.push(groupName)
                                }
                            });
                        }
                    }
                    else if (typeof element.productGroupName === 'string') {
                        if (!attributes.includes(element.productGroupName)) {
                            attributes.push(element.productGroupName)
                        }
                    }
                }
            });

            // if not single group present, remove none and show as it is record.
            if (attributes.length === 1 && attributes[0] === '') {
                items.forEach((element: any) => {
                    lineItems.push(new WorkOrderTableItem(element, documentData, currency).toString())
                });
                return lineItems
            }
        }

        attributes = attributes.sort((a: string, b: string) => a.localeCompare(b))
        //split the item based om attr
        var attributesGroupedItems: any[] = []
        attributes.forEach((element: any) => {
            var groupedItems: any[] = []
            items.forEach((item: any) => {
                if (grouping === CONSTANTS.PRODUCT_GROUP && !isMultiProductTable) {
                    if(typeof item.productGroupName === 'string') {
                        if (item.productGroupName === element) {
                            groupedItems.push(item)
                        }
                    }
                    else if (Array.isArray(item.productGroupName)) {
                        if (item.productGroupName.includes(element)) {
                            groupedItems.push(item)
                        }
                    }
                } else {
                    if (this.getAttributeString(item.customFields) === element) {
                        groupedItems.push(item)
                    }
                }
            });
            if (element === '') {
                element = 'none'
            }
            var val = {
                key: element,
                list: groupedItems
            }
            attributesGroupedItems.push(val)
        });

        var itemCount = 1
        attributesGroupedItems.forEach((element) => {
            if (element.list !== undefined && element.list !== null) {
                if (element.list.length > 0) {
                    this.groupHeaderIndexList.push(lineItems.length)
                    lineItems.push(new ProductTableGroupHeaderColumn(element.key).toString())
                }
                element.list.forEach((element: any) => {
                    var newElement = element
                    newElement.lineNumber = itemCount
                    itemCount += 1
                    lineItems.push(new WorkOrderTableItem(newElement, documentData, currency).toString())
                });
            }
        });
        return lineItems
    }

    static getGroupHeaderIndexList() {
        return this.groupHeaderIndexList
    }

    static getAttributeString(customFields: any) {
        var label = ''
        if (customFields !== undefined && customFields !== null) {
            if (customFields.length > 0) {
                customFields.forEach((element: any, index: number) => {
                    label = label + element.label + ' - ' + element.value
                    if (index < customFields.length - 1) {
                        label = label + ', '
                    }
                });
            }
        }
        return label
    }
    static getDefaultItemColumn(tableStyle: any, index: number, printScale: number = PRINT_SCALE) {
        var newItemColumn = new ItemColumn()
        newItemColumn.type = 'string'
        newItemColumn.style = new ColumnStyle()
        if (tableStyle.itemBackgroundColorIsAlternate) {
            newItemColumn.style.backgroundColor = (index % 2 !== 0) ? 'transparent' : tableStyle.itemBackgroundColor
        }
        else {
            newItemColumn.style.backgroundColor = tableStyle.itemBackgroundColor
        }
        newItemColumn.style.borderColor = tableStyle.itemBorderColor
        // newItemColumn.style.fontColor = tableStyle.headerTextColor
        newItemColumn.style.borderStyle = 'solid'
        newItemColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newItemColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.fontSize = FONT_SIZE.regular
        return newItemColumn
    }


    static getItemColumn(data: any, tableStyle: any, rawData: any) {
        var itemColumnList: ItemColumn[][] = []
        data.forEach((column: any[], index: number) => {
            var rowList: ItemColumn[] = []
            column.forEach(element => {
                var newItemColumn = this.getDefaultItemColumn(tableStyle, index, rawData.printScale)
                newItemColumn.value = element
                rowList.push(newItemColumn)
            });
            itemColumnList.push(rowList)
        });

        return itemColumnList
    }

    static onRowChange(data: any, rowIndex: number, action: string, onDataChangeCallback: any) {
        if (data === undefined || data === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems === undefined || data.lineItems === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems.length === 0 || data.lineItems.length === 1) {
            onDataChangeCallback(data)
        }

        var newArray: any[] = []
        data.lineItems.forEach((element: any, index: number) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= data.lineItems.length) {
                newIndex = data.lineItems.length - 1
            }
        }
        var selectedItem = data.lineItems[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            element.lineNumber = index + 1
        });
        data.lineItems = newArray
        onDataChangeCallback(data)
    }

    static getFooterData(documentData: any) {
        var footerValue: string[] = []
        return footerValue
    }


    static getFooterRow(data: any, tableColumnOptions: TableOption, tableStyle: any) {
        var keyValue: FooterRow[] = []
        return keyValue
    }

    static onUpdateTableWidth(header: HeaderColumn, percentage: number, data: any, columnOptions: any, callback: any) {
        if (columnOptions === undefined || columnOptions === null) {
            return
        }

        if (columnOptions.length === 0) {
            return
        }

        var dataColumn = columnOptions
        var isSelectedCount = dataColumn.filter((x: any) => x.isSelected).length - 1
        var offsetPercentage = percentage / isSelectedCount

        var newColumnOptions: any[] = []
        columnOptions.forEach((element: any) => {
            if (element.isSelected) {
                var item = element
                var width = element.width
                if (element.type === header.name) {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) + percentage
                        item.width = width + '%'
                    }
                }
                else {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) - offsetPercentage
                        item.width = width + '%'
                    }
                }
                newColumnOptions.push(item)
            }
            else {
                if (element.width !== undefined) {
                    element.width = '0%'
                }
                newColumnOptions.push(element)
            }
        });

        TemplateSettingsManager.updateTablePopupInfo(newColumnOptions)
        var newData = data
        newData.tableColumnPopup = newColumnOptions
        callback(newData)
    }
}
